import { Button, ModalFooter } from "shards-react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback } from "react";

const EditDocumentWindowFooter = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const {
    className,
    okButtonText,
    disabledButtons,
    disabledOkButton,
    disabledCancelButton,
    onClose
  } = props;

  const handleOkButtonClick = useCallback(() => {
    onClose({});
  }, [onClose]);

  const handleCancelButtonClick = useCallback(() => {
    onClose(false);
  }, [onClose]);

  return (
    <ModalFooter className={className}>
      <Button disabled={disabledButtons || disabledOkButton} onClick={handleOkButtonClick}>
        <span>{okButtonText}</span>
      </Button>
      <Button disabled={disabledButtons || disabledCancelButton} theme="secondary" onClick={handleCancelButtonClick}>
        <span>{uiTexts.close}</span>
      </Button>
    </ModalFooter>
  );
};

export default React.memo(EditDocumentWindowFooter);
