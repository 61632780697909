import { Badge, ConfidencePercents } from "lib/common";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import DocumentsStatuses from "lib/pages/DocumentsPage/DocumentsStatuses";
import React, { useMemo } from "react";

const { STATUSES } = DataConstants;

const StatusCell = ({
  status,
  recogniseStatus,
  confidenceExists,
  averageConfidence,
  duplicatedDocumentsIds,
  lastExportErrors
}) => {
  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const { labelLangId, iconComponent: Icon, errorIconComponent: ErrorIcon } = useMemo(() => {
    return DocumentsStatuses.getStatusData(status, activeOrganization.countryCode);
  }, [status, activeOrganization]);

  const toReviewStatus = status === STATUSES.TO_REVIEW;

  const errorState = status === STATUSES.TO_EXTRACT && recogniseStatus === "error";

  const hasDuplicates = !!(Array.isArray(duplicatedDocumentsIds) && duplicatedDocumentsIds.length);

  let badge = null;

  if (errorState) badge = <Badge data-theme="danger" value={uiTexts.error} />;
  else if (hasDuplicates) badge = <Badge data-theme="warning" value={uiTexts.duplicate} />;
  else if (toReviewStatus) {
    if (toReviewStatus && Array.isArray(lastExportErrors) && lastExportErrors.length) {
      badge = <Badge data-theme="danger" value={uiTexts.error} />;
    } else if (!confidenceExists) {
      badge = <Badge data-theme="primary" value={uiTexts.edited} />;
    }
  }

  return (
    <div
      title={uiTexts[labelLangId]}
      data-status={errorState ? recogniseStatus : status}
      data-percents={!badge && confidenceExists ? "" : undefined}
      data-badge={badge ? "" : undefined}>
      {errorState ? <ErrorIcon /> : <Icon />}
      {badge
        ? badge
        : (confidenceExists && (
          <ConfidencePercents
            backgroundInvert
            value={averageConfidence}
            component={Badge} />
        ))}
    </div>
  );
};

export default React.memo(StatusCell);
