import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Col, FormGroup, FormSelect, InputGroup, InputGroupAddon, InputGroupText, Row } from "shards-react";
import { FiCreditCard } from "react-icons/fi";
import { Form, ModalWindow } from "lib/common";
import { getAccountsData } from "selectors/accounts";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useMemo, useState } from "react";

const SelectPaymentAccountWindow = ({ onClose }) => {
  const { uiTexts } = useSelector(getTextsData);

  const accountsData = useSelector(getAccountsData);

  const {
    extraData: { xeroClassification, zohoClassification } = {}
  } = useSelector(getSelectedBusinessData);

  const categories = useMemo(() => {
    return (xeroClassification || zohoClassification || []).filter(({ paymentsAllowed }) => paymentsAllowed);
  }, [xeroClassification, zohoClassification]);

  const [serviceAccountId, setServiceAccountId] = useState("");

  const config = useMemo(() => {
    return {
      confirm: true,
      headerText: uiTexts.selectPaymentAccount,
      okButtonText: uiTexts.save
    };
  }, [uiTexts]);

  const handleWindowClose = useCallback((result) => {
    onClose(result && serviceAccountId);
  }, [serviceAccountId, onClose]);

  const handleInputChange = useCallback(({ target: { value } }) => {
    setServiceAccountId(value);
  }, []);

  return (
    <ModalWindow
      applyOnEnterPress
      className={Css.selectPaymentAccountWindow}
      config={config}
      disabledOkButton={!serviceAccountId}
      iconComponent={FiCreditCard}
      onClose={handleWindowClose}>
      <Form>
        <FormGroup row className={CommonCss.flexCenter}>
          <Row form>
            <Col>
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>
                    <span>{uiTexts.account}</span>
                  </InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  placeholder={serviceAccountId ? undefined : ""}
                  value={serviceAccountId || ""}
                  invalid={!serviceAccountId}
                  onChange={handleInputChange}>
                  {!serviceAccountId && <option value="">{uiTexts.selectPaymentAccount}</option>}
                  <optgroup label={uiTexts.bankAccounts}>
                    {accountsData.map(({ name, extraData: { xeroAccountId, zohoAccountId } = {} }) => {
                      const accountId = xeroAccountId || zohoAccountId;

                      return <option key={accountId} value={accountId}>{name}</option>;
                    }).filter(Boolean)}
                  </optgroup>
                  {!!categories.length && <optgroup label={uiTexts.otherAccounts}>
                    {categories.map(({ accountId, displayName }) => {
                      return <option key={accountId} value={accountId}>{displayName}</option>;
                    })}
                  </optgroup>}
                </FormSelect>
              </InputGroup>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </ModalWindow>
  );
};

export default SelectPaymentAccountWindow;
