import Css from "./style.module.scss";

import DocumentPreview from "lib/common/DocumentPreview";
import React from "react";

const PreviewCell = ({ recogniseData: { status: recogniseStatus, previewFile } }) => {
  return (
    <div className={Css.previewCell}>
      <DocumentPreview
        disabledDetach
        className={Css.preview}
        documentPreview={previewFile}
        documentRecogniseStatus={recogniseStatus} />
    </div>
  );
};

export default React.memo(PreviewCell);
