import Css from "./style.module.scss";

import { AutoCompleteInput } from "lib/common";
import { getCurrenciesData } from "selectors/metaData";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";

const SelectCurrencyInput = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const {
    name,
    right,
    dropup,
    value,
    invalid,
    valid,
    warning,
    disabled,
    className,
    inputClassName,
    noButton,
    placeholder = uiTexts.currency,
    inputProps,
    onChange
  } = props;

  const inputRef = useRef();

  const currenciesData = useSelector(getCurrenciesData);

  const currencies = useMemo(() => {
    return currenciesData.map(({ code }) => code);
  }, [currenciesData]);

  const [text, setText] = useState(currencies.includes(value) ? value : "");

  const textUpper = text.trim().toUpperCase();

  const currenciesList = useMemo(() => {
    return currencies.sort().map((code) => ({ value: code, label: code }));
  }, [currencies]);

  const handleChange = useCallback((event) => {
    setText(event.target.value);
  }, []);

  const handleAutoComplete = useCallback((event) => {
    setText(event.target.value);
    onChange(event);
  }, [onChange]);

  const handleBlur = useCallback((event) => {
    if (!currencies.includes(text) && currencies.includes(textUpper)) {
      event.target.value = textUpper;
      onChange(event);
    }
  }, [currencies, onChange, text, textUpper]);

  return (
    <div className={classNames(Css.selectCurrencyInput, className, !noButton && Css.withButton)}>
      <AutoCompleteInput
        ref={inputRef}
        name={name}
        right={right}
        dropup={dropup}
        disabled={!!disabled}
        inputClassName={classNames(
          Css.currencyInput,
          inputClassName,
          warning && !disabled && Css.warning
        )}
        placeholder={placeholder}
        value={text}
        valid={valid}
        invalid={invalid}
        toggleProps={inputProps}
        options={currenciesList}
        onChange={handleChange}
        onAutoComplete={handleAutoComplete}
        onBlur={handleBlur} />
    </div>
  );
};

export default React.memo(SelectCurrencyInput);
