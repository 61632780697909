import Css from "./style.module.scss";

import { BiLoaderAlt } from "react-icons/bi";
import { FiCloudOff, FiDownload, FiEdit3 as FiEdit, FiX } from "react-icons/fi";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useInViewport } from "react-in-viewport";
import DataConstants from "const/DataConstants";
import MainApiActions from "actions/MainApiActions";
import React, { useCallback, useEffect, useRef, useState } from "react";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import classNames from "classnames";

const { DOCUMENT_RECOGNISE_STATUSES } = DataConstants;

const DocumentPreview = (props) => {
  const {
    className,
    documentPreview,
    disabled,
    disabledEdit,
    disabledDetach,
    advancedDocumentsWorkflow,
    documentRecogniseStatus,
    onDocumentEdit,
    onDocumentDownload,
    onDocumentUnpair
  } = props;

  const rootRef = useRef();

  const { inViewport } = useInViewport(rootRef);

  const dispatch = useDispatch();

  const { uiTexts, documentUpdateActions } = useSelector(getTextsData);

  const [imageSrc, setImageSrc] = useState(null);

  const [imageLoaded, setImageLoaded] = useState(false);

  const recognizeError = documentRecogniseStatus === DOCUMENT_RECOGNISE_STATUSES.ERROR;

  const fetchData = useCallback((callback) => {
    let abort = false;

    setImageLoaded(false);
    setImageSrc(null);

    if (!documentPreview) return null;

    dispatch(MainApiActions.fetchAttachmentUrl(documentPreview, false, true)).then((response) => {
      if (!abort) {
        setImageSrc(response);
        Utils.preloadImages([response]).then(() => {
          if (!abort) {
            setImageLoaded(true);
            // eslint-disable-next-line promise/no-callback-in-promise
            if (callback) callback(response);
          }
        });
      }
    });

    return () => {
      abort = true;
    };
  }, [documentPreview, dispatch]);

  const handlePreviewClick = useCallback(async(event) => {
    event.stopPropagation();
    if (disabled) return;

    if (!imageSrc) return;
    dispatch(UiActions.showModalImages([""]));
    try {
      await Utils.preloadImages([imageSrc]);
      dispatch(UiActions.showModalImages([imageSrc]));
    } catch (error) {
      fetchData((response) => {
        dispatch(UiActions.showModalImages([response]));
      });
    }
  }, [disabled, dispatch, imageSrc, fetchData]);

  useEffect(() => {
    if (inViewport && documentPreview) {
      return fetchData();
    }

    return () => {};
  }, [inViewport, documentPreview, fetchData]);

  return (
    <div
      ref={rootRef}
      title={recognizeError ? documentUpdateActions.recogniseError : undefined}
      className={classNames(Css.documentPreview, documentPreview && Css.clickable, className)}
      onClick={handlePreviewClick}>
      {imageSrc && (
        <div
          title={uiTexts.preview}
          className={Css.preview}
          style={{ backgroundImage: `url(${imageSrc})` }} />
      )}
      {documentPreview
        ? (!imageLoaded && <div className={Css.loadingIcon}><BiLoaderAlt data-wait /></div>)
        : (
          recognizeError
            ? <FiCloudOff className={Css.error} />
            : <div className={Css.loadingIcon}><BiLoaderAlt data-wait /></div>
        )}
      {!disabled && (
        <div className={Css.actions}>
          {!advancedDocumentsWorkflow && onDocumentDownload && !!documentPreview && (
            <div
              title={uiTexts.download}
              className={Css.download}
              onClick={onDocumentDownload}>
              <FiDownload />
            </div>
          )}
          {!disabledEdit && advancedDocumentsWorkflow && onDocumentEdit && (
            <div
              title={uiTexts.edit}
              className={Css.edit}
              onClick={onDocumentEdit}>
              <FiEdit />
            </div>
          )}
          {!disabledDetach && <div
            title={uiTexts.detach}
            className={Css.detach}
            onClick={onDocumentUnpair}>
            <FiX />
          </div>}
        </div>
      )}
    </div>
  );
};

export default React.memo(DocumentPreview);
