import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Col, FormGroup, Row } from "shards-react";
import {
  FiArrowRight,
  FiChevronsRight,
  FiCloud,
  FiCopy,
  FiEdit,
  FiEyeOff,
  FiFileText,
  FiGitCommit,
  FiLink,
  FiMaximize2 as FiMaximize,
  FiPlus,
  FiPlusCircle,
  FiRefreshCw,
  FiSettings,
  FiUser,
  FiX
} from "react-icons/fi";
import { Preloader } from "lib/common";
import { checkContactsFetching, getContactsData } from "selectors/contacts";
import { getActiveOrganization } from "selectors/organizations";
import { getBanksData } from "selectors/metaData";
import { getDocumentEventsData } from "selectors/events";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DocumentsStatuses from "lib/pages/DocumentsPage/DocumentsStatuses";
import EventsActions from "actions/EventsActions";
import IntegrationServices from "const/IntegrationServices";
import MainApiRoutes from "const/MainApiRoutes";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const UPDATE_ACTIONS = {
  ADDED: "added",
  RECOGNISE_START: "recogniseStart",
  RECOGNISE_DONE: "recogniseDone",
  RECOGNISE_ERROR: "recogniseError",
  RECOGNISE_DUPLICATE: "recogniseDuplicate",
  STATUS_CHANGE: "statusChange",
  USER_UPDATE: "userUpdate",
  SYSTEM_UPDATE: "systemUpdate",
  USER_PAIRING: "userPairing",
  USER_UNPAIRING: "userUnpairing",
  SYSTEM_PAIRING: "systemPairing",
  SYSTEM_UNPAIRING: "systemUnpairing"
};

const UPDATE_FIELDS = {
  STATUS: "status",
  PAYMENT_TYPE: "paymentType",
  TYPE: "type",
  LAST_EXPORTED_TO: "lastExportedTo",
  TRANSACTION_IDS: "transactionIds",
  LINKED_DOCUMENTS_IDS: "linkedDocumentsIds",
  DUPLICATED_DOCUMENTS_IDS: "duplicatedDocumentsIds",
  ORIGINAL_DOCUMENT_ID: "originalDocumentId",
  AMOUNT: "amount",
  AMOUNT_VAT: "amountVat",
  AMOUNT_CORRECTION: "amountCorrection",
  AMOUNT_VAT_RATES: "amountVatRates",
  CURRENCY: "currency",
  ISSUE_DATE: "issueDate",
  DUE_DATE: "dueDate",
  TAX_DATE: "taxDate",
  VENDOR_ID: "vendorId",
  BANK_ACCOUNT: "payTo.bankAccount",
  BANK_CODE: "payTo.bankCode",
  PAYMENT_ACCOUNT: "payTo.serviceAccountId",
  PAYMENT_DATE: "paymentDate",
  LOCATION: "location.name",
  LINE_ITEMS: "lineItems",
  DESCRIPTION: "description",
  PRIVATE_NOTE: "privateNote",
  TAGS: "tags",
  VARIABLE_SYMBOL: "extra.VS",
  CONSTANT_SYMBOL: "extra.KS",
  SPECIFIC_SYMBOL: "extra.SS"
};

const FIELD_CHANGE_TYPES = {
  SET: "set",
  CHANGE: "change",
  UNSET: "unset"
};

const {
  DOCUMENT_PAYMENT_TYPES: { BUY, SELL },
  DOCUMENT_TYPES: { INVOICE, CREDIT_NOTE, RECEIPT, SALES_RECEIPT }
} = DataConstants;

const {
  ADDED,
  RECOGNISE_START,
  RECOGNISE_DONE,
  RECOGNISE_ERROR,
  RECOGNISE_DUPLICATE,
  STATUS_CHANGE,
  USER_UPDATE,
  SYSTEM_UPDATE,
  USER_PAIRING,
  USER_UNPAIRING,
  SYSTEM_PAIRING,
  SYSTEM_UNPAIRING
} = UPDATE_ACTIONS;

const {
  STATUS,
  PAYMENT_TYPE,
  TYPE,
  LAST_EXPORTED_TO,
  TRANSACTION_IDS,
  LINKED_DOCUMENTS_IDS,
  DUPLICATED_DOCUMENTS_IDS,
  ORIGINAL_DOCUMENT_ID,
  AMOUNT,
  AMOUNT_CORRECTION,
  AMOUNT_VAT_RATES,
  CURRENCY,
  ISSUE_DATE,
  DUE_DATE,
  TAX_DATE,
  VENDOR_ID,
  BANK_ACCOUNT,
  BANK_CODE,
  DESCRIPTION,
  PRIVATE_NOTE,
  TAGS,
  VARIABLE_SYMBOL,
  CONSTANT_SYMBOL,
  SPECIFIC_SYMBOL
} = UPDATE_FIELDS;

const { SET, CHANGE, UNSET } = FIELD_CHANGE_TYPES;

const { highlightText, positiveText, negativeText, warningText, specialText, neutralText, darkText, flexCenter } = CommonCss;

const ChangedFieldsList = React.memo(({ updates, prevRevDocument, currentRevDocument }) => {
  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const contactsData = useSelector(getContactsData);

  const banksData = useSelector(getBanksData);

  const { id: selectedBusinessId } = useSelector(getSelectedBusinessData);

  const normalizeValue = useCallback((value) => {
    if (typeof value === "string") value = value.trim();

    const valueCheck = value !== "" && value !== null && value !== undefined;

    if (valueCheck) {
      const arrayCheck = !Array.isArray(value)
        || (value.length !== 0 && value.some((item) => item && (typeof item !== "object" || item.value || item.description)));

      if (arrayCheck) {
        const objectCheck = typeof value !== "object" || Object.values(value).length !== 0;

        if (objectCheck) return value;
      }
    }

    return null;
  }, []);

  const convertArray = useCallback((value) => value.map((item) => `${item}`.trim()).join("; "), []);

  const convertDate = useCallback((value) => moment.utc(value).format(Constants.DATETIME_FORMATS.DATE_TEXT), []);

  const convertLinksList = useCallback((value, route) => (<>
    {value.map((text, index) => {
      text = `${text}`.trim();

      const query = Utils.objectToQueryString({ text });

      const linkUrl = `${window.origin}/${selectedBusinessId}${route}?${query}`;

      return (
        <Fragment key={text}>
          <a href={linkUrl} target="_blank">{text}</a>
          {index === value.length - 1 ? "" : ", "}
        </Fragment>
      );
    })}
  </>), [selectedBusinessId]);

  const fieldParameters = useMemo(
    () => ({
      [STATUS]: {
        label: uiTexts.status,
        convertor: (value) => uiTexts[
          DocumentsStatuses.getStatusData(value, activeOrganization.countryCode)?.labelLangId
        ] || value
      },
      [LAST_EXPORTED_TO]: {
        label: uiTexts.exportedTo,
        convertor: (value) => IntegrationServices.getByValue(value)?.label || value
      },
      [TRANSACTION_IDS]: {
        label: uiTexts.transactions,
        convertor: (value) => convertLinksList(value, UiRoutes.TRANSACTIONS)
      },
      [LINKED_DOCUMENTS_IDS]: {
        label: uiTexts.linked,
        convertor: (value) => convertLinksList(value, UiRoutes.DOCUMENTS)
      },
      [DUPLICATED_DOCUMENTS_IDS]: {
        label: uiTexts.duplicates,
        convertor: (value) => convertLinksList(value, UiRoutes.DOCUMENTS)
      },
      [PAYMENT_TYPE]: {
        label: uiTexts.operation,
        convertor: (value) => {
          const { type } = currentRevDocument;

          return {
            [BUY]: type === CREDIT_NOTE ? uiTexts.sellReturn : uiTexts.buyOperation,
            [SELL]: type === CREDIT_NOTE ? uiTexts.buyReturn : uiTexts.sellOperation
          }[value];
        }
      },
      [TYPE]: {
        label: uiTexts.type,
        convertor: (value) => {
          const { paymentType } = currentRevDocument;

          return (value && paymentType && {
            [INVOICE]: paymentType === BUY ? uiTexts.bill : uiTexts.invoice,
            [CREDIT_NOTE]: uiTexts.creditNote,
            [RECEIPT]: uiTexts.receipt,
            [SALES_RECEIPT]: uiTexts.salesReceipt
          }[value]) || uiTexts.unknown;
        }
      },
      [ORIGINAL_DOCUMENT_ID]: {
        label: `${uiTexts.document} #`
      },
      [AMOUNT]: {
        label: uiTexts.total,
        convertor: Utils.toMoneyString
      },
      [AMOUNT_CORRECTION]: {
        label: uiTexts.amountCorrection,
        convertor: Utils.toMoneyString
      },
      [AMOUNT_VAT_RATES]: {
        label: uiTexts.vat,
        convertor: (value) => convertArray(value.filter(({ base }) => base).map(({ base, rate, value: vat }) => {
          return `${rate}%: ${Utils.toMoneyString(base)}${vat ? ` [${Utils.toMoneyString(vat)}]` : ""}`;
        }))
      },
      [CURRENCY]: {
        label: uiTexts.currency
      },
      [ISSUE_DATE]: {
        label: uiTexts.issueDate,
        convertor: convertDate
      },
      [DUE_DATE]: {
        label: uiTexts.dueDate,
        convertor: convertDate
      },
      [TAX_DATE]: {
        label: uiTexts.taxDate,
        convertor: convertDate
      },
      [VENDOR_ID]: {
        label: uiTexts.contact,
        convertor: (value) => {
          return convertLinksList(
            [(contactsData.find(({ id }) => id === value) || {}).name || value],
            UiRoutes.CONTACTS
          );
        }
      },
      [BANK_ACCOUNT]: {
        label: uiTexts.bankAccount
      },
      [BANK_CODE]: {
        label: uiTexts.bank,
        convertor: (value) => (banksData.find(({ code }) => code === value) || {}).name || value
      },
      [DESCRIPTION]: {
        label: uiTexts.description
      },
      [PRIVATE_NOTE]: {
        label: uiTexts.memo
      },
      [VARIABLE_SYMBOL]: {
        label: "VS"
      },
      [CONSTANT_SYMBOL]: {
        label: "KS"
      },
      [SPECIFIC_SYMBOL]: {
        label: "SS"
      },
      [TAGS]: {
        label: uiTexts.tags,
        convertor: convertArray
      }
    }),
    [
      uiTexts,
      activeOrganization,
      contactsData,
      banksData,
      currentRevDocument,
      convertArray,
      convertDate,
      convertLinksList
    ]
  );

  const fieldChangeTypeProperties = useMemo(() => ({
    [SET]: {
      icon: FiPlus,
      iconClassName: positiveText
    },
    [CHANGE]: {
      icon: FiGitCommit,
      iconClassName: highlightText
    },
    [UNSET]: {
      icon: FiX,
      iconClassName: negativeText
    }
  }), []);

  const fieldsList = useMemo(() => {
    return Object.entries(fieldParameters)
      .map(([fieldKey, { label: fieldLabel, convertor: fieldValueConvertor = (value) => `${value}`.trim() }]) => {
        const rawFieldValue = (updates || {})[fieldKey] || Utils.getPropertyByPath(updates, fieldKey);

        const rawPrevFieldValue = (prevRevDocument || {})[fieldKey] || Utils.getPropertyByPath(prevRevDocument, fieldKey);

        let fieldValue = normalizeValue(rawFieldValue);

        let prevFieldValue = normalizeValue(rawPrevFieldValue);

        const fieldChangeType = prevFieldValue === null ? SET : (fieldValue === null ? UNSET : CHANGE);

        const valueChanged = !Utils.checkDeepEquality({ value: fieldValue }, { value: rawPrevFieldValue });

        prevFieldValue = prevFieldValue && fieldValueConvertor(prevFieldValue);
        fieldValue = fieldValue && fieldValueConvertor(fieldValue);

        if (fieldChangeType === SET ? fieldValue !== null : (rawFieldValue !== undefined && valueChanged)) {
          const { icon: Icon, iconClassName } = fieldChangeTypeProperties[fieldChangeType];

          const title = (() => {
            const valueA = typeof prevFieldValue === "object" ? rawPrevFieldValue : prevFieldValue;

            const valueB = typeof fieldValue === "object" ? rawFieldValue : fieldValue;

            return prevFieldValue === null ? valueB : `${valueA}${fieldValue === null ? "" : ` => ${valueB}`}`;
          })();

          return (
            <div key={fieldKey}>
              <Icon className={iconClassName} />
              <span title={title}>
                <span className={Css.fieldName}>{`${fieldLabel}: `}</span>
                {fieldChangeType !== SET && <span
                  className={classNames(Css.oldValue, fieldChangeType === UNSET && Css.deletedValue)}>
                  {prevFieldValue}
                </span>}
                {fieldChangeType === CHANGE && <FiArrowRight className={highlightText} />}
                {fieldChangeType !== UNSET && <span>{fieldValue}</span>}
              </span>
            </div>
          );
        }

        return null;
      });
  }, [updates, prevRevDocument, fieldParameters, fieldChangeTypeProperties, normalizeValue]);

  return fieldsList && <div className={Css.fieldsBlock}>{fieldsList}</div>;
});

const HistoryItem = React.memo(({ user, createdAt, action, updates, prevRevDocument, currentRevDocument }) => {
  const { uiTexts, documentUpdateActions: actionsTexts } = useSelector(getTextsData);

  const actionParamaters = useMemo(
    () => ({
      [ADDED]: {
        icon: FiPlusCircle,
        iconClassName: neutralText,
        label: actionsTexts.added
      },
      [RECOGNISE_START]: {
        icon: FiCloud,
        iconClassName: warningText,
        label: actionsTexts.recogniseStart,
        systemEvent: true
      },
      [RECOGNISE_DONE]: {
        icon: FiFileText,
        iconClassName: positiveText,
        label: actionsTexts.recogniseDone,
        systemEvent: true
      },
      [RECOGNISE_ERROR]: {
        icon: FiEyeOff,
        iconClassName: negativeText,
        label: actionsTexts.recogniseError,
        systemEvent: true
      },
      [RECOGNISE_DUPLICATE]: {
        icon: FiCopy,
        iconClassName: warningText,
        label: actionsTexts.recogniseDuplicate,
        systemEvent: true
      },
      [STATUS_CHANGE]: {
        icon: FiChevronsRight,
        iconClassName: darkText,
        label: actionsTexts.statusChange
      },
      [USER_UPDATE]: {
        icon: FiEdit,
        iconClassName: highlightText,
        label: actionsTexts.userUpdate
      },
      [SYSTEM_UPDATE]: {
        icon: FiRefreshCw,
        iconClassName: highlightText,
        label: actionsTexts.systemUpdate,
        systemEvent: true
      },
      [USER_PAIRING]: {
        icon: FiLink,
        iconClassName: specialText,
        label: actionsTexts.userPairing
      },
      [USER_UNPAIRING]: {
        icon: FiMaximize,
        iconClassName: negativeText,
        label: actionsTexts.userUnpairing
      },
      [SYSTEM_PAIRING]: {
        icon: FiLink,
        iconClassName: specialText,
        label: actionsTexts.systemPairing,
        systemEvent: true
      },
      [SYSTEM_UNPAIRING]: {
        icon: FiMaximize,
        iconClassName: negativeText,
        label: actionsTexts.systemUnpairing,
        systemEvent: true
      }
    }),
    [actionsTexts]
  );

  const {
    icon: Icon,
    label: actionLabel,
    iconClassName,
    systemEvent
  } = actionParamaters[action] || actionParamaters[SYSTEM_UPDATE];

  const eventDate = useMemo(() => {
    return moment.duration(moment.utc(createdAt).diff(moment.utc())).humanize(true);
  }, [createdAt]);

  const userFullName = useMemo(() => {
    const { fullName, email } = user || {};

    return fullName ? fullName + (fullName === email ? "" : ` <${email}>`) : email || uiTexts.unknown.toLowerCase();
  }, [user, uiTexts]);

  return (
    <Row form>
      <Col>
        <div className={Css.eventBlock}>
          <div className={classNames(Css.icon, flexCenter, iconClassName)}><Icon /></div>
          <div className={Css.content}>
            <div className={Css.titleBlock}>
              <div className={Css.title}>{actionLabel}</div>
              <div className={Css.subTitle}>
                {systemEvent ? <FiSettings /> : <FiUser />}
                <span>
                  <span className={Css.user}>
                    {systemEvent
                      ? uiTexts.system.toUpperCase()
                      : (userFullName)}
                  </span>
                  <span>, </span>
                  <span className={Css.date}>{eventDate}</span>
                </span>
              </div>
            </div>
            <ChangedFieldsList updates={updates} prevRevDocument={prevRevDocument} currentRevDocument={currentRevDocument} />
          </div>
        </div>
      </Col>
    </Row>
  );
});

const EditDocumentWindowHistoryBlock = ({ documentId }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const fetchingContacts = useSelector(checkContactsFetching);

  const eventsData = useSelector(getDocumentEventsData);

  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    dispatch(EventsActions.fetchEventsByRoute(MainApiRoutes.DOCUMENTS, documentId)).then(() => {
      setDataLoaded(true);
    });
  }, [documentId, dispatch, setDataLoaded]);

  return (
    <FormGroup row className={Css.editDocumentWindowHistoryBlock}>
      {!dataLoaded || fetchingContacts
        ? <Preloader />
        : (eventsData.length
          ? eventsData.map(({ rev, action, user, document, updates, createdAt }, index) => {
            const oldest = index === 0;

            return (
              <Fragment key={rev}>
                {!!index && <div data-placeholder><br /></div>}
                <HistoryItem
                  user={user}
                  createdAt={createdAt}
                  action={action ? action : SYSTEM_UPDATE}
                  updates={oldest && action === ADDED ? document : updates}
                  prevRevDocument={oldest || eventsData.length === 1 ? null : eventsData[index - 1].document}
                  currentRevDocument={eventsData[index].document} />
              </Fragment>
            );
          })
          : <div className={classNames(Css.noDataContent, CommonCss.noDataContent)}>
            <div>
              <div><FiRefreshCw /></div>
              <div>{uiTexts.noChanges}</div>
            </div>
          </div>
        )}
    </FormGroup>
  );
};

export default React.memo(EditDocumentWindowHistoryBlock);
