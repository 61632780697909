import { ActionsButtons } from "lib/common";
import DataConstants from "const/DataConstants";
import React from "react";

const { STATUSES } = DataConstants;

const ActionsCell = (props) => {
  const {
    id,
    status,
    attachmentKey,
    recogniseStatus,
    disabledApprove,
    onDocumentDownload,
    onDocumentEdit,
    onDocumentDelete,
    onDocumentApprove
  } = props;

  const extractStatus = status === STATUSES.TO_EXTRACT;

  const errorState = recogniseStatus === "error";

  return (
    <ActionsButtons
      itemId={id}
      disabledDownload={!attachmentKey}
      disabledEdit={extractStatus}
      disabledDelete={extractStatus && !errorState}
      disabledApprove={disabledApprove}
      onDownload={onDocumentDownload}
      onEdit={onDocumentEdit}
      onDelete={onDocumentDelete}
      onApprove={onDocumentApprove} />
  );
};

export default React.memo(ActionsCell);
