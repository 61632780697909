import CommonCss from "lib/common/style.module.scss";

import { AutoTooltip } from "lib/common";
import { FiUser, FiUserCheck, FiUserPlus } from "react-icons/fi";
import { getSelectedBusinessData } from "selectors/businesses";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";

const AddressCell = ({ name, paymentType, vendorId, status, businessUser }) => {
  const { settings: { allowClientPayeeSelection } = {} } = useSelector(getSelectedBusinessData);

  const content = paymentType && name;

  const exportedStatus = status === DataConstants.STATUSES.EXPORTED;

  const vendorIcon = vendorId
    ? <FiUserCheck className={CommonCss.positiveText} />
    : <FiUserPlus className={CommonCss.highlightText} />;

  if (content) {
    return (
      <AutoTooltip>
        {exportedStatus || (businessUser && !allowClientPayeeSelection)
          ? <FiUser />
          : vendorIcon}
        <span>{content}</span>
      </AutoTooltip>
    );
  }

  return Constants.EMPTY_PLACEHOLDER;
};

export default React.memo(AddressCell);
