import Css from "./style.module.scss";

import { Button, FormSelect, InputGroup, InputGroupAddon } from "shards-react";
import { DateTimeInput, DebounceInput, Form } from "lib/common";
import { FiFilter, FiX } from "react-icons/fi";
import { bind } from "decko";
import { connect } from "react-redux";
import { getAccountsData } from "selectors/accounts";
import {
  getSelectedBusinessData,
  getSelectedBusinessUsersData
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import DataConstants from "const/DataConstants";
import DateTimeRangePicker from "../DateTimeRangePicker";
import React, { PureComponent } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const mapStateToProps = (state) => ({
  textsData: getTextsData(state),
  usersData: getSelectedBusinessUsersData(state),
  selectedBusinessData: getSelectedBusinessData(state),
  accountsData: getAccountsData(state)
});

@connect(mapStateToProps, null)
class DocumentsFilter extends PureComponent {
  constructor(props) {
    super(props);

    const { initialValue: { fromDate, toDate, text, paymentType, paidWith, createdBy, createdAt } } = this.props;

    this.state = {
      dateRangeEnabled: !!(fromDate && toDate),
      textEnabled: !!text,
      paymentTypeEnabled: !!paymentType,
      paidWithEnabled: !!paidWith,
      createdByEnabled: !!createdBy,
      createdAtEnabled: !!createdAt,
      fromDate: fromDate || "",
      toDate: toDate || "",
      text: text || "",
      paymentType: paymentType || "",
      paidWith: paidWith || "",
      createdBy: createdBy || "",
      createdAt: createdAt || ""
    };
  }

  emitChangeEvent() {
    const {
      dateRangeEnabled,
      textEnabled,
      paymentTypeEnabled,
      fromDate,
      toDate,
      text,
      paymentType,
      paidWithEnabled,
      paidWith,
      createdByEnabled,
      createdBy,
      createdAtEnabled,
      createdAt
    } = this.state;

    const filters = {};

    if (dateRangeEnabled) filters.fromDate = fromDate;
    if (dateRangeEnabled) filters.toDate = toDate;
    if (textEnabled) filters.text = text;
    if (paymentTypeEnabled) filters.paymentType = paymentType;
    if (paidWithEnabled) filters.paidWith = paidWith;
    if (createdByEnabled) filters.createdBy = createdBy;
    if (createdAtEnabled) filters.createdAt = createdAt;
    this.props.onChange(filters);
  }

  componentDidMount() {
    this.dateTimeEndRef = document.querySelector(`.${Css.documentsFilter} .dateTimeEnd`);
  }

  @bind
  handleFromDateInputChange(value) {
    const { toDate } = this.state;

    const formattedValue = value ? Utils.formatApiDate(value) : "";

    this.setState(
      () => ({
        fromDate: formattedValue,
        toDate: (!value || !toDate || moment.utc(toDate).isBefore(moment.utc(value))) ? formattedValue : toDate,
        dateRangeEnabled: !!toDate
      }),
      () => {
        if (toDate) this.emitChangeEvent();
        if (this.dateTimeEndRef) this.dateTimeEndRef.focus();
      }
    );
  }

  @bind
  handleToDateInputChange(value) {
    const { fromDate } = this.state;

    const formattedValue = value ? Utils.formatApiDate(value) : "";

    this.setState(
      () => ({
        toDate: formattedValue,
        fromDate: (!value || !fromDate || moment.utc(value).isBefore(moment.utc(fromDate))) ? formattedValue : fromDate,
        dateRangeEnabled: !!fromDate
      }),
      () => fromDate && this.emitChangeEvent()
    );
  }

  @bind
  handleTextInputChange({ target: { value } }) {
    this.setState({ text: value });
  }

  @bind
  handleTextInputInputComplete(value) {
    const text = value.trim();

    this.setState(
      () => ({ text, textEnabled: !!text }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handlePaymentTypeSelectChange({ target: { value } }) {
    this.setState(
      () => ({ paymentType: value, paymentTypeEnabled: !!value }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handlePaidWithSelectChange({ target: { value } }) {
    this.setState(
      () => ({ paidWith: value, paidWithEnabled: !!value }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleCreatedByChange({ target: { value } }) {
    this.setState(
      () => ({ createdBy: value, createdByEnabled: !!value }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleCreateAtInputChange(value) {
    const formattedValue = value ? moment(value).format() : "";

    this.setState(
      () => ({ createdAt: formattedValue, createdAtEnabled: !!value }),
      () => value && this.emitChangeEvent()
    );
  }

  @bind
  handleToggleDateRangeButtonClick() {
    this.setState(
      ({ dateRangeEnabled, fromDate, toDate }) => ({
        dateRangeEnabled: !dateRangeEnabled,
        fromDate: dateRangeEnabled ? "" : fromDate,
        toDate: dateRangeEnabled ? "" : toDate
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleToggleTextButtonClick() {
    this.setState(
      ({ textEnabled, text }) => ({
        textEnabled: !textEnabled,
        text: textEnabled ? "" : text
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleTogglePaymentTypeButtonClick() {
    this.setState(
      ({ paymentTypeEnabled, paymentType }) => ({
        paymentTypeEnabled: !paymentTypeEnabled,
        paymentType: paymentTypeEnabled ? "" : paymentType
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleTogglePaidWithButtonClick() {
    this.setState(
      ({ paidWithEnabled, paidWith }) => ({
        paidWithEnabled: !paidWithEnabled,
        paidWith: paidWithEnabled ? "" : paidWith
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleToggleCreatedByButtonClick() {
    this.setState(
      ({ createdByEnabled, createdBy }) => ({
        createdByEnabled: !createdByEnabled,
        createdBy: createdByEnabled ? "" : createdBy
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleToggleCreatedAtButtonClick() {
    this.setState(
      ({ createdAtEnabled, createdAt }) => ({
        createdAtEnabled: !createdAtEnabled,
        createdAt: createdAtEnabled ? "" : createdAt
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleResetButtonClick() {
    this.setState({
      fromDate: "",
      toDate: "",
      text: "",
      paymentType: "",
      paidWith: "",
      createdBy: "",
      createdAt: "",
      dateRangeEnabled: true,
      textEnabled: true,
      paymentTypeEnabled: true,
      paidWithEnabled: true,
      createdByEnabled: true,
      createdAtEnabled: true
    }, () => this.emitChangeEvent());
  }

  render() {
    const {
      textsData: { uiTexts },
      usersData,
      disabled,
      className,
      compact,
      hideDatePicker
    } = this.props;

    const {
      dateRangeEnabled,
      textEnabled,
      paymentTypeEnabled,
      paidWithEnabled,
      createdByEnabled,
      createdAtEnabled,
      fromDate,
      toDate,
      text,
      paymentType,
      createdBy,
      createdAt
    } = this.state;

    const hasFilters = dateRangeEnabled
      || textEnabled
      || paymentTypeEnabled
      || paidWithEnabled
      || createdByEnabled
      || createdAtEnabled;

    return (
      <div className={classNames(Css.documentsFilter, className)}>
        <Form>
          {!hideDatePicker && (
            <DateTimeRangePicker
              disabled={disabled}
              startDate={fromDate}
              endDate={toDate}
              fromDate={fromDate}
              toDate={toDate}
              onFromDateChange={this.handleFromDateInputChange}
              onToDateChange={this.handleToDateInputChange}
              onToggleClick={this.handleToggleDateRangeButtonClick} />
          )}
          <InputGroup>
            <DebounceInput
              disabled={disabled}
              value={text}
              placeholder={uiTexts.searchText}
              onChange={this.handleTextInputChange}
              onInputComplete={this.handleTextInputInputComplete} />
            <InputGroupAddon type="append">
              <Button
                theme={textEnabled ? "primary" : "secondary"}
                disabled={disabled || !text}
                onClick={this.handleToggleTextButtonClick}>
                <FiFilter />
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <InputGroup>
            <FormSelect
              disabled={disabled}
              placeholder={paymentType ? undefined : ""}
              value={paymentType}
              onChange={this.handlePaymentTypeSelectChange}>
              {!paymentType && <option value="">{uiTexts.paymentType}</option>}
              <option value={DataConstants.DOCUMENT_PAYMENT_TYPES.SELL}>{uiTexts.revenue}</option>
              <option value={DataConstants.DOCUMENT_PAYMENT_TYPES.BUY}>{uiTexts.expenses}</option>
              {paymentType && <option value="">{uiTexts.all}</option>}
            </FormSelect>
            <InputGroupAddon type="append">
              <Button
                theme={paymentTypeEnabled ? "primary" : "secondary"}
                disabled={disabled || !paymentType}
                onClick={this.handleTogglePaymentTypeButtonClick}>
                <FiFilter />
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <InputGroup>
            <FormSelect
              disabled={disabled}
              placeholder={createdBy ? undefined : ""}
              value={createdBy}
              onChange={this.handleCreatedByChange}>
              {!createdBy && <option value="">{uiTexts.uploadedBy}</option>}
              {usersData.map(({ id, fullName }) => (
                <option key={id} value={id}>{fullName}</option>
              ))}
              {createdBy && <option value="">{uiTexts.all}</option>}
            </FormSelect>
            <InputGroupAddon type="append">
              <Button
                theme={createdByEnabled ? "primary" : "secondary"}
                disabled={disabled || !createdBy}
                onClick={this.handleToggleCreatedByButtonClick}>
                <FiFilter />
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <InputGroup>
            <DateTimeInput
              disabled={disabled}
              value={createdAt ? Utils.formatNoTimeZoneDate(createdAt, false) : ""}
              placeholder={uiTexts.uploadDate}
              onChange={this.handleCreateAtInputChange} />
            <InputGroupAddon type="append">
              <Button
                theme={createdAtEnabled ? "primary" : "secondary"}
                disabled={disabled || !createdAt}
                onClick={this.handleToggleCreatedAtButtonClick}>
                <FiFilter />
              </Button>
            </InputGroupAddon>
          </InputGroup>
          {hasFilters && (
            <InputGroup className={Css.resetButtonGroup}>
              <Button theme="danger" outline onClick={this.handleResetButtonClick}>
                <FiX />
                {!compact && <span>{uiTexts.resetFilters}</span>}
              </Button>
            </InputGroup>
          )}
        </Form>
      </div>
    );
  }
}

export default DocumentsFilter;
