import Css from "./style.module.scss";

import { Button, ModalFooter } from "shards-react";
import { EDIT_DOCUMENT_WINDOW_MODES } from "lib/pages/DocumentsPage/lib/EditDocumentWindow/lib/EditDocumentWindowModeSelector";
import { FiArrowLeft } from "react-icons/fi";
import { bind } from "decko";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import React, { PureComponent } from "react";

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

@connect(mapStateToProps, null)
class EditDocumentWindowFooter extends PureComponent {
  @bind
  handleOkButtonClick() {
    this.props.onClose({});
  }

  @bind
  handleExcludeClick() {
    this.props.onClose({ toExclude: true });
  }

  @bind
  handleToReportButtonClick() {
    this.props.onClose({ toReport: true });
  }

  @bind
  handleAddContactButtonClick() {
    this.props.onAdditionalBlockClose(this.props.additionalBlockData);
  }

  @bind
  handleBackButtonClick() {
    this.props.onAdditionalBlockClose(false);
  }

  @bind
  handleCancelButtonClick() {
    this.props.onClose(false);
  }

  render() {
    const { DEFAULT, LINE_ITEMS, CONTACT } = EDIT_DOCUMENT_WINDOW_MODES;

    const {
      textsData: { uiTexts },
      selectedEditTab,
      okButtonText,
      showToReportButton,
      showAddContactButton,
      disabledButtons,
      disabledToReportButton,
      disabledOkButton,
      showExcludeButton,
      disabledAddContactButton,
      disabledBackButton,
      disabledCancelButton
    } = this.props;

    const saveAndApproveButtons = <>
      {showToReportButton && <Button
        theme="success"
        disabled={disabledButtons || disabledToReportButton}
        onClick={this.handleToReportButtonClick}>
        <span>{uiTexts.approveAndClose}</span>
      </Button>}
      <Button disabled={disabledButtons || disabledOkButton} onClick={this.handleOkButtonClick}>
        <span>{okButtonText}</span>
      </Button>
      {showExcludeButton && (
        <Button theme="accent" disabled={disabledButtons} onClick={this.handleExcludeClick}>
          <span>{uiTexts.exclude}</span>
        </Button>
      )}
    </>;

    const mainActionButtons = {
      [DEFAULT]: saveAndApproveButtons,
      [LINE_ITEMS]: saveAndApproveButtons,
      [CONTACT]: showAddContactButton && <Button
        disabled={disabledButtons || disabledAddContactButton}
        onClick={this.handleAddContactButtonClick}>
        <span>{uiTexts.addContact}</span>
      </Button>
    }[selectedEditTab];

    return (
      <ModalFooter className={Css.editDocumentWindowFooter}>
        {selectedEditTab !== DEFAULT && <Button
          theme="secondary"
          disabled={disabledButtons || disabledBackButton}
          onClick={this.handleBackButtonClick}>
          <FiArrowLeft />
          <span>{uiTexts.document}</span>
        </Button>}
        {mainActionButtons}
        <Button disabled={disabledButtons || disabledCancelButton} theme="secondary" onClick={this.handleCancelButtonClick}>
          <span>{uiTexts.close}</span>
        </Button>
      </ModalFooter>
    );
  }
}

export default EditDocumentWindowFooter;
