import CommonCss from "lib/common/style.module.scss";

import { FaCommentDots, FaRegCommentDots } from "react-icons/fa";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";

const { STATUSES } = DataConstants;

const NotificationsCell = (props) => {
  const {
    transactionId,
    status,
    allCommentsCount,
    unreadCommentsCount,
    onNotificationsLinkClick
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const extractStatus = status === STATUSES.TO_EXTRACT;

  if (extractStatus) return Constants.EMPTY_PLACEHOLDER;

  return (
    <span
      title={allCommentsCount || uiTexts.addComment}
      data-id={transactionId}
      className={unreadCommentsCount ? CommonCss.negativeText : undefined}
      onClick={onNotificationsLinkClick}>
      {allCommentsCount
        ? <>
          <FaCommentDots />
          <span><b>{allCommentsCount}</b></span>
        </>
        : <FaRegCommentDots />}
    </span>
  );
};

export default React.memo(NotificationsCell);
