import CommonCss from "lib/common/style.module.scss";

import { AutoTooltip } from "lib/common";
import { FiCopy, FiFileText } from "react-icons/fi";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";

const { STATUSES } = DataConstants;

const DocumentIdCell = ({ id, value, status, duplicatedDocumentsIds, attachmentKey }) => {
  const extractionStatus = status === STATUSES.TO_EXTRACT;

  const hasDuplicates = !!(Array.isArray(duplicatedDocumentsIds) && duplicatedDocumentsIds.length);

  return (
    <AutoTooltip active={!!(!extractionStatus && value)}>
      <span data-id={id}>
        {extractionStatus
          ? Constants.EMPTY_PLACEHOLDER
          : <>
            {hasDuplicates
              ? <FiCopy className={CommonCss.warningText} />
              : <FiFileText className={attachmentKey ? "" : CommonCss.negativeText} />}
            {!!value && <span>{value}</span>}
          </>}
      </span>
    </AutoTooltip>
  );
};

export default React.memo(DocumentIdCell);
