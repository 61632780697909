import TableCss from "lib/common/Table/style.module.scss";

import Constants from "const/Constants";

import React from "react";

const TagsCell = ({ value: tags = [] }) => {
  if (!tags.length) return Constants.EMPTY_PLACEHOLDER;

  return (
    <span title={tags.join(", ")} className={TableCss.tagsCell}>
      <span>{tags[0]}</span>
      {tags.length > 1 && <span data-rest>{`...+${tags.length - 1}`}</span>}
    </span>
  );
};

export default React.memo(TagsCell);
