import CommonCss from "lib/common/style.module.scss";

import { AutoTooltip } from "lib/common";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";
import Utils from "utils/Utils";

const { STATUSES } = DataConstants;

const AmountCell = ({ status, paymentType, amountVatRates, amountBase, amountVat, amountCorrection, currency }) => {
  const buyPayment = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY;

  if (status === STATUSES.TO_EXTRACT) return Constants.EMPTY_PLACEHOLDER;

  const value = Utils.getDocumentAmountData(amountVatRates, amountBase, amountVat, amountCorrection).total;

  const content = Utils.toMoneyString(value, currency);

  return (
    <AutoTooltip
      active={value !== undefined}
      className={paymentType && value ? CommonCss.coloredNumber : ""}
      data-negative={buyPayment ? "" : undefined}>
      {content}
    </AutoTooltip>
  );
};

export default React.memo(AmountCell);
