import { AutoTooltip } from "lib/common";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";
import Utils from "utils/Utils";

const { STATUSES } = DataConstants;

const AmountVatCell = ({ status, amountVatRates, amountBase, amountVat, currency }) => {
  if (status === STATUSES.TO_EXTRACT) return Constants.EMPTY_PLACEHOLDER;

  const value = Utils.getDocumentAmountData(amountVatRates, amountBase, amountVat).vat;

  const content = Utils.toMoneyString(value, currency);

  return <AutoTooltip active={!!value}>{content}</AutoTooltip>;
};

export default React.memo(AmountVatCell);
