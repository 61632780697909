import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const PLACES = {
  top: "top",
  bottom: "bottom"
};

const STYLES = {
  [PLACES.top]: Css.top,
  [PLACES.bottom]: Css.bottom
};

const Tooltip = ({ className, opened = false, place = PLACES.top, ...rest }) => {
  if (!opened) return null;

  return (
    <div className={classNames(Css.tooltip, STYLES[place], className)} {...rest} />
  );
};

export default React.memo(Tooltip);
