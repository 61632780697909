import Css from "./style.module.scss";

import { Avatar } from "lib/common";
import Constants from "const/Constants";
import React from "react";
import Tooltip from "lib/common/Tooltip";
import moment from "moment";

const UploadedByCell = ({ value, createdAt }) => {
  const createdAtText = createdAt ? moment(createdAt).format(Constants.DATETIME_FORMATS.DATETIME_TEXT) : null;

  return (
    <div className={Css.uploadedByCell}>
      <Avatar name={value} size="small" />
      <Tooltip className={Css.tooltip} opened>
        <div className={Css.content}>{value}</div>
        {createdAtText && <div className={Css.content}>{createdAtText}</div>}
      </Tooltip>
    </div>
  );
};

export default React.memo(UploadedByCell);
