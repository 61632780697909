import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { FiAlertTriangle } from "react-icons/fi";
import { Preloader } from "lib/common";
import { bind, debounce } from "decko";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import BrowserEvents from "const/BrowserEvents";
import Constants from "const/Constants";
import MainApiActions from "actions/MainApiActions";
import React, { PureComponent, Suspense } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const Viewer = React.lazy(() => import("./dll"));

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchAttachmentUrl: (...args) => dispatch(MainApiActions.fetchAttachmentUrl(...args))
});

@connect(mapStateToProps, mapDispatchToProps)
class PdfViewer extends PureComponent {
  static DEFAULT_ZOOM_LEVEL = "PageWidth";

  state = { pdfUrl: null, windowResized: false };

  mounted = false;

  componentDidMount() {
    const { RESIZE, FULLSCREEN_CHANGE, ORIENTATION_CHANGE } = BrowserEvents;

    const { attachment, fetchAttachmentUrl } = this.props;

    fetchAttachmentUrl(attachment).then((pdfUrl) => this.setState({ pdfUrl }));
    window.addEventListener(RESIZE, this.handleWindowResize);
    window.addEventListener(FULLSCREEN_CHANGE, this.handleWindowResize);
    window.addEventListener(ORIENTATION_CHANGE, this.handleWindowResize);
    this.mounted = true;
  }

  componentWillUnmount() {
    const { RESIZE, FULLSCREEN_CHANGE, ORIENTATION_CHANGE } = BrowserEvents;

    window.removeEventListener(RESIZE, this.handleWindowResize);
    window.removeEventListener(FULLSCREEN_CHANGE, this.handleWindowResize);
    window.removeEventListener(ORIENTATION_CHANGE, this.handleWindowResize);
    this.mounted = false;
  }

  @bind
  @debounce(Constants.WINDOW_RESIZE_DEBOUNCE_DELAY)
  handleWindowResize() {
    this.setState({ windowResized: true });
  }

  @bind
  renderViewer(props) {
    if (this.state.windowResized) {
      Utils.deferredRun(() => {
        if (this.mounted) {
          this.setState({ windowResized: false }, () => {
            props.zoom(PdfViewer.DEFAULT_ZOOM_LEVEL);
          });
        }
      });
    }

    return props.viewer;
  }

  render() {
    const { textsData: { errors }, attachment } = this.props;

    const { pdfUrl } = this.state;

    return (
      <div className={classNames(Css.pdfViewer, CommonCss.flexCenter)}>
        {attachment && attachment.key
          ? <Suspense fallback={<Preloader />}>
            {pdfUrl
              ? <Viewer fileUrl={pdfUrl} defaultScale={PdfViewer.DEFAULT_ZOOM_LEVEL} render={this.renderViewer} />
              : <Preloader />}
          </Suspense>
          : <div className={Css.errorBlock}><FiAlertTriangle /><span>{errors.attachmentNotRecognised}</span></div>}
      </div>
    );
  }
}

export default PdfViewer;
