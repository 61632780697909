import Css from "./style.module.scss";

import { Col, FormGroup, Row } from "shards-react";
import { FiCopy } from "react-icons/fi";
import { Form, ModalWindow, Preloader } from "lib/common";
import { getCurrenciesData } from "selectors/metaData";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useMainApi } from "hooks";
import DocumentsTable from "./lib/DocumentsTable";
import MainApiActions from "actions/MainApiActions";
import MainApiRoutes from "const/MainApiRoutes";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import RestApi from "api/RestApi";
import UiActions from "actions/UiActions";

const { BUSINESSES, DOCUMENTS } = MainApiRoutes;

const DuplicatedDocumentsWindow = ({ disabled, documentData, onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const currenciesData = useSelector(getCurrenciesData);

  const { duplicatedDocumentsIds = [] } = documentData || {};

  const [modalShown, setModalShown] = useState(false);

  let [{ results: duplicatedDocuments }, fetchingData] = useMainApi({
    method: RestApi.REQUEST_METHODS.POST,
    initialData: { results: [] },
    fetchCondition: modalShown,
    parameters: [`${BUSINESSES}/${selectedBusinessId + DOCUMENTS}`, null, { documentsIds: duplicatedDocumentsIds }],
    dependencies: [modalShown]
  });

  duplicatedDocuments = useMemo(() => {
    return duplicatedDocuments.filter(({ id }) => {
      return duplicatedDocumentsIds.includes(id);
    }).map(({ currency, ...restDocument }) => ({
      ...restDocument,
      currency: currenciesData.some(({ code }) => code === currency) ? currency : null
    }));
  }, [duplicatedDocumentsIds, duplicatedDocuments, currenciesData]);

  const handleShow = useCallback(() => {
    setModalShown(true);
  }, []);

  const handleClose = useCallback((result) => {
    if (result) onClose({ duplicatedDocumentsIds: [] });
    else onClose({});
  }, [onClose]);

  const handleTableDocumentPreview = useCallback((documentId) => {
    const { recogniseData: { previewFile } = {} } = [documentData, ...duplicatedDocuments].find(({ id }) => id === documentId);

    dispatch(MainApiActions.fetchAttachmentUrl(previewFile)).then((link) => {
      if (link) dispatch(UiActions.showModalImages([link]));
    });
  }, [documentData, duplicatedDocuments, dispatch]);

  const handleTableDocumentDelete = useCallback(() => {
    onClose({ deleteDocument: true });
  }, [onClose]);

  useEffect(() => {
    if (!fetchingData && duplicatedDocuments.length === 0) onClose({ duplicatedDocumentsIds: [] });
  }, [fetchingData, duplicatedDocuments, onClose]);

  return (
    <ModalWindow
      className={Css.duplicatedDocumentsWindow}
      bodyClassName={Css.windowBody}
      config={{
        confirm: true,
        headerText: uiTexts.similarDocuments,
        okButtonText: uiTexts.continueEditing,
        cancelButtonText: uiTexts.close
      }}
      disabledOkButton={disabled || fetchingData}
      iconComponent={FiCopy}
      onShow={handleShow}
      onClose={handleClose}>
      <Form>
        <FormGroup row>
          <Row form>
            <Col className={Css.tableColumn}>
              {fetchingData || !duplicatedDocuments.length ? <Preloader />
                : <DocumentsTable
                  disabled={disabled}
                  data={duplicatedDocuments}
                  baseDocument={documentData}
                  onDocumentPreview={handleTableDocumentPreview}
                  onDocumentDelete={handleTableDocumentDelete} />}
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </ModalWindow>
  );
};

export default DuplicatedDocumentsWindow;
