import CommonCss from "lib/common/style.module.scss";

import TableCss from "lib/common/Table/style.module.scss";

import Css from "./style.module.scss";

import { ActionsButtons } from "lib/common";
import { FiFileText, FiUser, FiUserCheck, FiUserPlus } from "react-icons/fi";
import { getActiveOrganization } from "selectors/organizations";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DocumentsStatuses from "lib/pages/DocumentsPage/DocumentsStatuses";
import React, { useMemo } from "react";
import TableNew, { TableCell, TableHead, TableRow } from "../TableNew";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const LinkDocumentsTable = (props) => {
  const {
    data,
    page,
    count,
    sortBy,
    disabled,
    pageSize,
    fetching,
    sortOrder,
    className,
    linkedDocuments,
    onDocumentLink,
    onDocumentPair,
    onDocumentLinkDismiss,
    onDocumentPreview,
    onSortChange,
    onPageChange,
    onPageSizeChange
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const sortedData = useMemo(() => {
    return [...data].sort(({ id: idA }, { id: idB }) => {
      const sortA = +linkedDocuments.includes(idA);

      const sortB = +linkedDocuments.includes(idB);

      return sortB - sortA;
    });
  }, [data, linkedDocuments]);

  const tableColumns = useMemo(() => [
    {
      accessor: "status",
      className: classNames(TableCss.statusCell, CommonCss.flexCenter, Css.statusCell),
      header: uiTexts.status,
      Cell: ({ value: status, original: { recogniseData = {} } }) => {
        const statusData = DocumentsStatuses.getStatusData(status, activeOrganization.countryCode);

        if (!statusData) return Constants.EMPTY_PLACEHOLDER;

        const { labelLangId, iconComponent: Icon, errorIconComponent: ErrorIcon } = statusData;

        const errorState = recogniseData.status === "error";

        return (
          <div title={uiTexts[labelLangId]} data-status={errorState ? recogniseData.status : status}>
            {errorState ? <ErrorIcon /> : <Icon />}
          </div>
        );
      }
    },
    {
      id: "id",
      className: classNames(TableCss.linkCell, Css.idCell),
      header: `${uiTexts.document} #`,
      Cell: ({ original: { id, originalDocumentId: value, status, attachment = {} } }) => {
        if (status === DataConstants.STATUSES.TO_EXTRACT) return Constants.EMPTY_PLACEHOLDER;

        const queryString = Utils.objectToQueryString({ editItem: id });

        return (
          <a
            title={value || ""}
            href={`${window.origin}/${selectedBusinessData.id + UiRoutes.DOCUMENTS}?${queryString}`}
            target="_blank">
            <FiFileText className={attachment.key ? undefined : CommonCss.negativeText} />
            <span>{value || "n/a"}</span>
          </a>
        );
      }
    },
    {
      accessor: "address",
      className: Css.addressCell,
      header: uiTexts.payee,
      Cell: ({
        value: { name } = {},
        original: { paymentType, vendorId, status }
      }) => {
        const content = paymentType && name;

        const exportedStatus = status === DataConstants.STATUSES.EXPORTED;

        const vendorIcon = vendorId
          ? <FiUserCheck className={CommonCss.positiveText} />
          : <FiUserPlus className={CommonCss.highlightText} />;

        return (
          <span title={content || ""}>
            {content
              ? (exportedStatus ? <FiUser /> : vendorIcon)
              : null}
            <span>{content || Constants.EMPTY_PLACEHOLDER}</span>
          </span>
        );
      }
    },
    {
      accessor: "issueDate",
      className: Css.dateCell,
      header: uiTexts.issueDate,
      Cell: ({ value }) => value
        ? moment.utc(value).format(Constants.DATETIME_FORMATS.DATE_TEXT)
        : Constants.EMPTY_PLACEHOLDER
    },
    {
      accessor: "amount",
      className: Css.amountCell,
      header: uiTexts.total,
      Cell: ({ original: { paymentType, amountVatRates, amountBase, amountVat, amountCorrection, currency } }) => {
        const buyPayment = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY;

        const value = Utils.getDocumentAmountData(amountVatRates, amountBase, amountVat, amountCorrection).total;

        return (
          <span className={paymentType ? CommonCss.coloredNumber : ""} data-negative={buyPayment ? "" : undefined}>
            {Utils.toMoneyString(value, currency)}
          </span>
        );
      }
    },
    {
      id: "description",
      className: Css.descriptionCell,
      header: uiTexts.description,
      Cell: ({ original: { description: value } }) => (
        <span title={value || ""}>{value || Constants.EMPTY_PLACEHOLDER}</span>
      )
    },
    {
      id: "actions",
      className: classNames(Css.actionsCell, CommonCss.flexEnd),
      headerClassName: CommonCss.flexEnd,
      header: uiTexts.actions,
      Cell: ({ original: { id, status, recogniseData: { previewFile } = {} } }) => {
        const documentLinked = linkedDocuments.includes(id);

        const extractStatus = status === DataConstants.STATUSES.TO_EXTRACT;

        return (
          <ActionsButtons
            itemId={id}
            className={Css.actionsButtons}
            disabledPreview={!previewFile || !previewFile.key}
            disabledLink={extractStatus || disabled}
            disabledPair={extractStatus || disabled}
            disabledDismiss={disabled}
            onPreview={onDocumentPreview}
            onLink={!documentLinked && onDocumentLink}
            onPair={!documentLinked && onDocumentPair}
            onDismiss={documentLinked && onDocumentLinkDismiss} />
        );
      }
    }
  ], [
    uiTexts,
    disabled,
    selectedBusinessData,
    activeOrganization,
    linkedDocuments,
    onDocumentPreview,
    onDocumentLink,
    onDocumentPair,
    onDocumentLinkDismiss
  ]);

  return (
    <TableNew
      count={count}
      page={page}
      pageSize={pageSize}
      sortBy={sortBy}
      sortOrder={sortOrder}
      showFooter={!fetching}
      className={classNames(TableCss.table, Css.transactionsTable, className)}
      onSortChange={onSortChange}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}>
      <TableRow className={Css.tableHead}>
        {tableColumns.map(({ id, show = true, header, className: cellClassName, accessor }) => show && (
          <TableHead
            key={id || accessor}
            className={cellClassName}
            accessor={accessor}>{header}</TableHead>
        ))}
      </TableRow>
      {sortedData.map((rowData, index) => (
        <TableRow key={rowData.id} highlight={linkedDocuments.includes(rowData.id)}>
          {tableColumns.map(({ id, show = true, accessor, className: cellClassName, Cell }) => show && (
            <TableCell key={id || accessor} className={cellClassName}>
              <Cell index={index} value={rowData[accessor]} original={rowData} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableNew>
  );
};

export default React.memo(LinkDocumentsTable);
