
import * as Yup from "yup";

class ValidationSchemes {
  static DOCUMENT_VALIDATION_SCHEMA = Yup.object().shape({
    id: Yup.string().nullable(),
    type: Yup.string().required(),
    paymentType: Yup.string().required(),
    issueDate: Yup.string().trim().required(),
    dueDate: Yup.string().trim(),
    taxDate: Yup.string().trim(),
    paymentDate: Yup.string().trim(),
    currency: Yup.string().required(),
    amountBase: Yup.number().min(0).required(),
    amountVat: Yup.number().required(),
    amountCorrection: Yup.number(),
    amountVatRates: Yup.array().of(Yup.object()),
    detailedTaxRates: Yup.array().of(Yup.object()),
    vendorId: Yup.string().trim().required(),
    originalDocumentId: Yup.string().trim()
      .when("$paid", {
        is: true,
        then: Yup.string().trim().required()
      }),
    description: Yup.string().trim(),
    privateNote: Yup.string().trim(),
    tags: Yup.array().of(Yup.string().trim()),
    paid: Yup.boolean(),
    location: Yup.object({
      id: Yup.string()
        .when("name", {
          is: (name) => name,
          then: Yup.string().trim().required()
        }),
      name: Yup.string().trim()
    }),
    address: Yup.object({
      name: Yup.string().trim(),
      idNumber: Yup.string().trim(),
      vatId: Yup.string().trim()
    }),
    payTo: Yup.object({
      bankAccount: Yup.string().trim(),
      bankCode: Yup.string().trim(),
      bankName: Yup.string().trim(),
      serviceAccountId: Yup.string().trim()
        .when("$paid", {
          is: true,
          then: Yup.string().trim().required()
        })
    }),
    extra: Yup.object({
      VS: Yup.string().trim(),
      KS: Yup.string().trim(),
      SS: Yup.string().trim()
    })
  });

  static RECEIPT_VALIDATION_SCHEMA = Yup.object().shape({
    id: Yup.string().nullable(),
    type: Yup.string().required(),
    paymentType: Yup.string().required(),
    exportAs: Yup.string(),
    issueDate: Yup.string().required(),
    paymentDate: Yup.string().trim().when("$paid", {
      is: true,
      then: Yup.string().trim().required()
    }),
    currency: Yup.string().required(),
    amountBase: Yup.number().min(0).required(),
    amountVat: Yup.number().required(),
    amountCorrection: Yup.number(),
    amountVatRates: Yup.array().of(Yup.object()),
    detailedTaxRates: Yup.array().of(Yup.object()),
    vendorId: Yup.string().required(),
    originalDocumentId: Yup.string().trim()
      .when("$paid", {
        is: true,
        then: Yup.string().trim().required()
      }),
    description: Yup.string().trim(),
    privateNote: Yup.string().trim(),
    tags: Yup.array().of(Yup.string().trim()),
    location: Yup.object({
      id: Yup.string()
        .when("name", {
          is: (name) => name,
          then: Yup.string().trim().required()
        }),
      name: Yup.string().trim()
    }),
    address: Yup.object({
      name: Yup.string(),
      idNumber: Yup.string(),
      vatId: Yup.string()
    }),
    payTo: Yup.object({
      serviceAccountId: Yup.string().trim()
        .when("$paid", {
          is: true,
          then: Yup.string().trim().required()
        })
    })
  });

  static ACCOUNTANT_DOCUMENT_LINE_ITEM_VALIDATION_SCHEMA = Yup.object().shape({
    lineItems: Yup.array().of(Yup.object().shape({
      vatRate: Yup.number().min(0).required(),
      amountBase: Yup.number().required(),
      amountVat: Yup.number().required(),
      amountTotal: Yup.number().required(),
      quantity: Yup.number().positive().nullable(),
      description: Yup.string().trim().required(),
      class: Yup.object({
        id: Yup.string()
          .when("name", {
            is: (name) => name,
            then: Yup.string().trim().required()
          }),
        name: Yup.string().trim()
      }),
      project: Yup.object({
        id: Yup.string()
          .when("name", {
            is: (name) => name,
            then: Yup.string().trim().required()
          }),
        name: Yup.string().trim()
      }),
      taxRate: Yup.object({
        id: Yup.string()
          .when("name", {
            is: (name) => name,
            then: Yup.string().trim().required(),
            otherwise: Yup.string().trim()
              .test(function(value) {
                // eslint-disable-next-line no-invalid-this
                const { taxRequired } = this.options.context;

                return !taxRequired || value;
              })
          }),
        name: Yup.string().trim()
      }),
      item: Yup.object()
        .when("category", {
          is: (category) => !category || !category.name,
          then: Yup.object().shape({
            id: Yup.string().trim().required(),
            name: Yup.string().trim().required()
          }).required(),
          otherwise: Yup.object().when("item", {
            is: (item) => !!item?.name,
            then: Yup.object().shape({
              id: Yup.string().trim().required(),
              name: Yup.string().trim().required()
            }).required()
          })
        }),
      category: Yup.object()
        .when("$xeroBusiness", {
          is: true,
          then: Yup.object().shape({
            code: Yup.string().trim().required(),
            displayName: Yup.string().trim().required()
          }).required(),
          otherwise: Yup.object().when("item", {
            is: (item) => !item || !item.name,
            then: Yup.object().shape({
              code: Yup.string().trim().required(),
              displayName: Yup.string().trim().required()
            }).required()
          })
        })
    }, ["item", "category"])).required()
  });

  static BUSINESS_USER_TRANSACTION_VALIDATION_SCHEMA = Yup.object().shape({
    vendorId: Yup.string(),
    reason: Yup.string()
      .when("category", {
        is: ({ code } = {}) => !code,
        then: Yup.string().trim().required()
      }),
    category: Yup.object()
      .when("reason", {
        is: "",
        then: Yup.object().shape({
          code: Yup.string().required(),
          name: Yup.string().required()
        }).required()
      }),
    class: Yup.object({
      id: Yup.string()
        .when("name", {
          is: (name) => name,
          then: Yup.string().trim().required()
        }),
      name: Yup.string().trim()
    }),
    location: Yup.object({
      id: Yup.string()
        .when("name", {
          is: (name) => name,
          then: Yup.string().trim().required()
        }),
      name: Yup.string().trim()
    }),
    project: Yup.object({
      id: Yup.string()
        .when("name", {
          is: (name) => name,
          then: Yup.string().trim().required()
        }),
      name: Yup.string().trim()
    })
  }, ["reason", "category"]);

  static ACCOUNTANT_TRANSACTION_VALIDATION_SCHEMA = Yup.object().shape({
    vendorId: Yup.string()
      .when(["$requireVendorId"], {
        is: true,
        then: Yup.string().required()
      }),
    reason: Yup.string().trim(),
    category: Yup.object()
      .when("$usesItems", {
        is: false,
        then: Yup.object().shape({
          code: Yup.string().required(),
          name: Yup.string().required()
        }).required()
      }),
    item: Yup.object()
      .when("$usesItems", {
        is: true,
        then: Yup.object().shape({
          id: Yup.string().required(),
          name: Yup.string().required()
        }).required()
      }),
    class: Yup.object({
      id: Yup.string()
        .when("name", {
          is: (name) => name,
          then: Yup.string().trim().required()
        }),
      name: Yup.string().trim()
    }),
    location: Yup.object({
      id: Yup.string()
        .when("name", {
          is: (name) => name,
          then: Yup.string().trim().required()
        }),
      name: Yup.string().trim()
    }),
    project: Yup.object({
      id: Yup.string()
        .when("name", {
          is: (name) => name,
          then: Yup.string().trim().required()
        }),
      name: Yup.string().trim()
    }),
    taxRate: Yup.object({
      id: Yup.string()
        .when("name", {
          is: (name) => name,
          then: Yup.string().trim().required()
        }),
      name: Yup.string().trim()
    })
  });
}

export default ValidationSchemes;
