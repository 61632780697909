import { AutoTooltip } from "lib/common";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";

const { STATUSES } = DataConstants;

const MAX_VISIBLE_DESCRIPTION_LENGTH = 200;

const DescriptionCell = ({ value, status, recogniseStatus, fileName }) => {
  const { uiTexts, documentUpdateActions } = useSelector(getTextsData);

  const extractStatus = status === STATUSES.TO_EXTRACT;

  const errorState = recogniseStatus === "error";

  let content = extractStatus
    ? (errorState ? documentUpdateActions.recogniseError : uiTexts.processing)
    : (value || "");

  if (extractStatus && fileName) content = `${content}: ${fileName}`;
  if (content?.length > MAX_VISIBLE_DESCRIPTION_LENGTH) {
    content = `${content.slice(0, MAX_VISIBLE_DESCRIPTION_LENGTH)}...`;
  }

  return <AutoTooltip active={!!content}>{content || Constants.EMPTY_PLACEHOLDER}</AutoTooltip>;
};

export default React.memo(DescriptionCell);
