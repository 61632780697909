import CommonCss from "lib/common/style.module.scss";

import TableCss from "lib/common/Table/style.module.scss";

import {
  EDIT_DOCUMENT_WINDOW_MODES
} from "lib/pages/DocumentsPage/lib/EditDocumentWindow/lib/EditDocumentWindowModeSelector";
import { FormCheckbox } from "shards-react";
import { Table } from "lib/common";
import { bind } from "decko";
import { checkDocumentsFetching } from "selectors/documents";
import { checkMatchesFetching } from "selectors/businesses";
import { connect } from "react-redux";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import AbstractDataTable from "lib/common/AbstractDataTable";
import ActionsCell from "./lib/ActionsCell";
import AddressCell from "./lib/AddressCell";
import AmountCell from "./lib/AmountCell";
import AmountVatCell from "./lib/AmountVatCell";
import DataConstants from "const/DataConstants";
import DescriptionCell from "./lib/DescriptionCell";
import DocumentIdCell from "./lib/DocumentIdCell";
import DocumentsTablePopup from "./lib/DocumentsTablePopup";
import IdColumnHeader from "./lib/IdColumnHeader";
import IssueDateCell from "./lib/IssueDateCell";
import NotificationsCell from "./lib/NotificationsCell";
import PreviewCell from "./lib/PreviewCell";
import React from "react";
import StatusCell from "./lib/StatusCell";
import TagsCell from "./lib/TagsCell";
import UploadedByCell from "./lib/UploadedByCell";
import classNames from "classnames";

const { STATUSES } = DataConstants;

const SQUARE_CELL_WIDTH = 50;

const DOCUMENT_ID_CELL_MAX_WIDTH = 100;

const STATUS_CELL_MAX_WIDTH = 82;

const DATE_CELL_MAX_WIDTH = 100;

const ADDRESS_CELL_MAX_WIDTH = 150;

const AMOUNT_WIDE_CELL_MAX_WIDTH = 150;

const PLACEHOLDER_CELL_MAX_WIDTH = 20;

const NOTIFICATIONS_CELL_MAX_WIDTH = 60;

const UPLOADED_BY_CELL_MAX_WIDTH = 60;

const PREVIEW_CELL_MAX_WIDTH = 80;

const ACTIONS_CELL_WIDTH = 100;

const mapStateToProps = (state) => ({
  fetchingData: checkDocumentsFetching(state) || checkMatchesFetching(state),
  activeOrganization: getActiveOrganization(state),
  textsData: getTextsData(state),
  userRole: getUserRole(state)
});

@connect(mapStateToProps)
class DocumentsTable extends AbstractDataTable {
  get tableColumns() {
    const {
      textsData: { uiTexts },
      businessData: { vatPayer },
      data,
      disabled,
      simplifiedLayout,
      documentsReadyToApproveIds,
      onDocumentEdit,
      onDocumentDelete,
      onDocumentApprove,
      onDocumentManageDuplicates
    } = this.props;

    const { checkBoxes } = this.state;

    return [
      {
        accessor: "id",
        sortable: false,
        width: SQUARE_CELL_WIDTH,
        className: TableCss.squareCell,
        headerClassName: TableCss.squareCell,
        Header: () => {
          return (
            <IdColumnHeader
              data={data}
              checkBoxes={checkBoxes}
              disabled={disabled}
              onHeaderCheckBoxChange={this.handleHeaderCheckBoxChange} />
          );
        },
        Cell: ({ value, original: { status } }) => {
          return (
            <FormCheckbox
              data-id={value}
              disabled={disabled || status === STATUSES.TO_EXTRACT}
              checked={!!checkBoxes[value]}
              onChange={this.handleCheckBoxChange} />
          );
        }
      },
      {
        accessor: "status",
        clickable: true,
        maxWidth: STATUS_CELL_MAX_WIDTH,
        className: classNames(TableCss.statusCell, CommonCss.flexCenter),
        Header: uiTexts.status,
        Cell: ({
          value: status,
          original: {
            duplicatedDocumentsIds,
            lastExportErrors,
            recogniseData: { status: recogniseStatus, confidence, averageConfidence } = {}
          }
        }) => {
          return (
            <StatusCell
              status={status}
              recogniseStatus={recogniseStatus}
              confidenceExists={!!confidence}
              averageConfidence={averageConfidence}
              duplicatedDocumentsIds={duplicatedDocumentsIds}
              lastExportErrors={lastExportErrors} />
          );
        }
      },
      {
        accessor: "originalDocumentId",
        maxWidth: DOCUMENT_ID_CELL_MAX_WIDTH,
        clickable: true,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: `${uiTexts.document} #`,
        Cell: ({ value, original: { id, status, duplicatedDocumentsIds, attachment: { key: attachmentKey } = {} } }) => {
          return (
            <DocumentIdCell
              id={id}
              value={value}
              status={status}
              duplicatedDocumentsIds={duplicatedDocumentsIds}
              attachmentKey={attachmentKey} />
          );
        }
      },
      {
        accessor: "address",
        maxWidth: ADDRESS_CELL_MAX_WIDTH,
        clickable: true,
        className: classNames(TableCss.addressCell, CommonCss.overflowVisible),
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.payee,
        Cell: ({
          value: { name } = {},
          original: { id, paymentType, vendorId, status }
        }) => {
          return (
            <AddressCell
              id={id}
              name={name}
              paymentType={paymentType}
              vendorId={vendorId}
              status={status} />
          );
        }
      },
      {
        accessor: "issueDate",
        clickable: true,
        maxWidth: DATE_CELL_MAX_WIDTH,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.issueDate,
        Cell: ({ value }) => <IssueDateCell value={value} />
      },
      {
        id: "amount",
        clickable: true,
        maxWidth: AMOUNT_WIDE_CELL_MAX_WIDTH,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.total,
        Cell: ({ original: { status, paymentType, amountVatRates, amountBase, amountVat, amountCorrection, currency } }) => {
          return (
            <AmountCell
              status={status}
              paymentType={paymentType}
              amountVatRates={amountVatRates}
              amountBase={amountBase}
              amountVat={amountVat}
              amountCorrection={amountCorrection}
              currency={currency} />
          );
        }
      },
      {
        id: "amountVat",
        clickable: true,
        show: vatPayer,
        maxWidth: AMOUNT_WIDE_CELL_MAX_WIDTH,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.vat,
        Cell: ({ original: { status, amountVatRates, amountBase, amountVat, currency } }) => {
          return (
            <AmountVatCell
              status={status}
              amountVatRates={amountVatRates}
              amountBase={amountBase}
              amountVat={amountVat}
              currency={currency} />
          );
        }
      },
      {
        accessor: "description",
        clickable: true,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.description,
        Cell: ({ value, original: { status, attachment = {}, recogniseData: { status: recogniseStatus } = {} } }) => {
          return (
            <DescriptionCell
              value={value}
              status={status}
              recogniseStatus={recogniseStatus}
              fileName={attachment.originalName} />
          );
        }
      },
      {
        accessor: "tags",
        clickable: true,
        show: !simplifiedLayout,
        Header: uiTexts.tags,
        Cell: ({ value }) => <TagsCell value={value} />
      },
      {
        sortable: false,
        maxWidth: PLACEHOLDER_CELL_MAX_WIDTH
      },
      {
        sortable: false,
        show: !simplifiedLayout,
        maxWidth: NOTIFICATIONS_CELL_MAX_WIDTH,
        className: TableCss.notificationsCell,
        headerClassName: CommonCss.flexStart,
        Header: <span title={uiTexts.comments}>{uiTexts.comments}</span>,
        Cell: ({ original: { id, status, comments: { all, unread } = {} } }) => {
          return (
            <NotificationsCell
              transactionId={id}
              status={status}
              allCommentsCount={all}
              unreadCommentsCount={unread}
              onNotificationsLinkClick={this.handleNotificationsLinkClick} />
          );
        }
      },
      {
        sortable: false,
        accessor: "createdBy",
        maxWidth: UPLOADED_BY_CELL_MAX_WIDTH,
        className: TableCss.uploadedByCell,
        headerClassName: CommonCss.flexStart,
        Header: <span title={uiTexts.uploaded}>{uiTexts.uploaded}</span>,
        Cell: ({ value, original: { createdAt } }) => {
          const { email, fullName } = value || {};

          return <UploadedByCell value={fullName || email} createdAt={createdAt} />;
        }
      },
      {
        sortable: false,
        maxWidth: PREVIEW_CELL_MAX_WIDTH,
        className: TableCss.previewCell,
        headerClassName: TableCss.previewCell,
        Header: uiTexts.preview,
        Cell: ({ original: { id, recogniseData } }) => {
          return (
            <PreviewCell key={id} recogniseData={recogniseData} />
          );
        }
      },
      {
        id: "actions",
        sortable: false,
        width: ACTIONS_CELL_WIDTH,
        className: CommonCss.flexEnd,
        Header: uiTexts.actions,
        Cell: ({
          original: {
            id,
            status,
            duplicatedDocumentsIds,
            attachment: { key: attachmentKey } = {},
            recogniseData: { status: recogniseStatus } = {}
          }
        }) => {
          return (
            <ActionsCell
              id={id}
              status={status}
              attachmentKey={attachmentKey}
              recogniseStatus={recogniseStatus}
              disabledApprove={!documentsReadyToApproveIds.includes(id)}
              onDocumentEdit={Array.isArray(duplicatedDocumentsIds) && duplicatedDocumentsIds.length
                ? onDocumentManageDuplicates : onDocumentEdit}
              onDocumentDelete={onDocumentDelete}
              onDocumentApprove={onDocumentApprove} />
          );
        }
      }
    ];
  }

  @bind
  getTrProps(state, { original: { id, status, deleted, duplicatedDocumentsIds, lastExportErrors } }) {
    const { documentsReadyToApproveIds } = this.props;

    const duplicated = Array.isArray(duplicatedDocumentsIds) && duplicatedDocumentsIds.length;

    const exportError = status === STATUSES.TO_REVIEW && Array.isArray(lastExportErrors) && lastExportErrors.length;

    const readyToApprove = documentsReadyToApproveIds.includes(id);

    return {
      "data-warning": (duplicated || exportError) ? "" : undefined,
      "data-negative": deleted ? "" : undefined,
      "data-positive": !duplicated && readyToApprove ? "" : undefined,
      disabled: this.props.disabled
    };
  }

  @bind
  getTheadThProps() {
    return { disabled: this.props.disabled };
  }

  @bind
  getTdProps(state, { original: { id, status, duplicatedDocumentsIds } }, { clickable }) {
    const { disabled, onDocumentManageDuplicates, onDocumentEdit } = this.props;

    const extractStatus = status === DataConstants.STATUSES.TO_EXTRACT;

    if (!clickable || extractStatus || disabled) return {};

    return {
      style: { cursor: "pointer" },
      onClick: Array.isArray(duplicatedDocumentsIds) && duplicatedDocumentsIds.length
        ? () => { onDocumentManageDuplicates(id); }
        : () => { onDocumentEdit(id); }
    };
  }

  @bind
  handleNotificationsLinkClick({ currentTarget: { dataset: { id } } }) {
    this.props.onDocumentEdit(id, EDIT_DOCUMENT_WINDOW_MODES.COMMENTS);
  }

  @bind
  handleTablePopupApprove() {
    this.props.bulkDocumentApprove(this.props.documentsReadyToApproveIds);
  }

  render() {
    const {
      data,
      page,
      pageSize,
      totalDataCount,
      fetchingData,
      documentsReadyToApproveIds,
      onFetchData
    } = this.props;

    return (
      <>
        <Table
          manual
          showPageSizeOptions
          columns={this.tableColumns}
          data={data}
          page={page}
          pages={Math.ceil((totalDataCount || data.length) / pageSize)}
          pageSize={pageSize}
          showPagination={totalDataCount > pageSize}
          getPaginationProps={this.getPaginationProps}
          getTheadThProps={this.getTheadThProps}
          getTrProps={this.getTrProps}
          getTdProps={this.getTdProps}
          onFetchData={onFetchData} />
        {!fetchingData && (
          <DocumentsTablePopup
            value={documentsReadyToApproveIds.length}
            onApprove={this.handleTablePopupApprove} />
        )}
      </>
    );
  }
}

export default DocumentsTable;
