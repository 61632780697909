import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Button, ModalHeader } from "shards-react";
import { FiArrowLeftCircle, FiArrowRightCircle, FiXCircle as FiCloseCircle, FiCopy, FiFileText } from "react-icons/fi";
import { bind } from "decko";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import React, { PureComponent } from "react";
import classNames from "classnames";

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

@connect(mapStateToProps, null)
class EditDocumentWindowHeader extends PureComponent {
  @bind
  handlePrevDocumentButtonClick() {
    this.props.onDocumentSwitch(-1);
  }

  @bind
  handleNextDocumentButtonClick() {
    this.props.onDocumentSwitch(1);
  }

  @bind
  handleCancelButtonClick() {
    this.props.onClose(false);
  }

  render() {
    const {
      textsData: { uiTexts },
      titleText,
      subTitleText,
      className,
      documentIndex,
      documentsCount,
      disabledButtons,
      duplicatedDocument
    } = this.props;

    return (
      <ModalHeader tag="div" className={classNames(className, Css.editDocumentWindowHeader)} titleClass={Css.modalTitle}>
        <div className={Css.text}>
          <h5>
            {duplicatedDocument ? <FiCopy className={CommonCss.warningText} /> : <FiFileText />}
            <span>{titleText}</span>
          </h5>
          <div>{subTitleText}</div>
        </div>
        <div className={Css.switchButtons}>
          <Button
            outline
            theme="secondary"
            disabled={documentIndex === 0 || disabledButtons}
            onClick={this.handlePrevDocumentButtonClick}>
            <FiArrowLeftCircle />
          </Button>
          <div>{`${documentIndex + 1}/${documentsCount}`}</div>
          <Button
            outline
            theme="secondary"
            disabled={documentIndex === documentsCount - 1 || disabledButtons}
            onClick={this.handleNextDocumentButtonClick}>
            <span>{uiTexts.nextDocument}</span>
            <FiArrowRightCircle />
          </Button>
        </div>
        <div>
          <Button
            theme="secondary"
            disabled={disabledButtons}
            onClick={this.handleCancelButtonClick}>
            <FiCloseCircle />
          </Button>
        </div>
      </ModalHeader>
    );
  }
}

export default EditDocumentWindowHeader;
