import CommonCss from "lib/common/style.module.scss";

import TableCss from "lib/common/Table/style.module.scss";

import Css from "./style.module.scss";

import { ActionsButtons, Table } from "lib/common";
import { FiAlertTriangle, FiFileText, FiUser, FiUserCheck, FiUserPlus } from "react-icons/fi";
import { getActiveOrganization } from "selectors/organizations";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserRestrictions } from "selectors/user";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DocumentsStatuses from "lib/pages/DocumentsPage/DocumentsStatuses";
import React, { useCallback, useMemo } from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { TABLE_PAGE_SIZE } = Constants;

const DOCUMENT_ID_CELL_WIDTH = 110;

const STATUS_CELL_WIDTH = 64;

const DATE_CELL_WIDTH = 100;

const NUMBER_CELL_WIDTH = 150;

const ACTIONS_CELL_WIDTH = 80;

const DocumentsTable = ({
  disabled,
  data,
  baseDocument,
  onDocumentPreview,
  onDocumentDelete
}) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const userRestrictions = useSelector(getUserRestrictions);

  const getTrProps = useCallback((state, { original: { id } }) => {
    if (id === baseDocument.id) return { "data-warning": "" };

    return {};
  }, [baseDocument]);

  const tableColumns = useMemo(() => [
    {
      accessor: "status",
      sortable: false,
      width: STATUS_CELL_WIDTH,
      className: classNames(TableCss.statusCell, CommonCss.flexCenter),
      Header: uiTexts.status,
      Cell: ({ value: status, original: { recogniseData = {} } }) => {
        const { labelLangId, iconComponent: Icon, errorIconComponent: ErrorIcon } = DocumentsStatuses.getStatusData(
          status,
          activeOrganization.countryCode
        );

        const errorState = recogniseData.status === "error";

        return (
          <div title={uiTexts[labelLangId]} data-status={errorState ? recogniseData.status : status}>
            {errorState ? <ErrorIcon /> : <Icon />}
          </div>
        );
      }
    },
    {
      accessor: "originalDocumentId",
      sortable: false,
      width: DOCUMENT_ID_CELL_WIDTH,
      headerClassName: CommonCss.flexStart,
      className: TableCss.linkCell,
      Header: `${uiTexts.document} #`,
      Cell: ({ value, original: { id, status, attachment = {} } }) => {
        if (status === DataConstants.STATUSES.TO_EXTRACT) return Constants.EMPTY_PLACEHOLDER;

        const queryString = Utils.objectToQueryString({ editItem: id });

        return (
          <a
            href={`${window.origin}/${selectedBusinessData.id + UiRoutes.DOCUMENTS}?${queryString}`}
            title={value || ""}
            target="_blank">
            <FiFileText className={attachment.key ? undefined : CommonCss.negativeText} />
            <span>{value || "n/a"}</span>
          </a>
        );
      }
    },
    {
      accessor: "address",
      sortable: false,
      headerClassName: CommonCss.flexStart,
      Header: uiTexts.payee,
      Cell: ({
        value: { name } = {},
        original: { paymentType, vendorId, status }
      }) => {
        const content = paymentType && name;

        const exportedStatus = status === DataConstants.STATUSES.EXPORTED;

        const vendorIcon = vendorId
          ? <FiUserCheck className={CommonCss.positiveText} />
          : <FiUserPlus className={CommonCss.highlightText} />;

        return (
          <span title={content || ""}>
            {content
              ? (exportedStatus ? <FiUser /> : vendorIcon)
              : null}
            <span>{content || Constants.EMPTY_PLACEHOLDER}</span>
          </span>
        );
      }
    },
    {
      accessor: "issueDate",
      sortable: false,
      width: DATE_CELL_WIDTH,
      headerClassName: CommonCss.flexStart,
      Header: uiTexts.issueDate,
      Cell: ({ value }) => value ? moment.utc(value).format(Constants.DATETIME_FORMATS.DATE_TEXT) : Constants.EMPTY_PLACEHOLDER
    },
    {
      id: "amount",
      sortable: false,
      width: NUMBER_CELL_WIDTH,
      headerClassName: CommonCss.flexStart,
      Header: uiTexts.total,
      Cell: ({ original: { paymentType, amountVatRates, amountBase, amountVat, amountCorrection, currency } }) => {
        const buyPayment = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY;

        const value = Utils.getDocumentAmountData(amountVatRates, amountBase, amountVat, amountCorrection).total;

        return (
          <span className={paymentType ? CommonCss.coloredNumber : ""} data-negative={buyPayment ? "" : undefined}>
            {Utils.toMoneyString(value, currency)}
          </span>
        );
      }
    },
    {
      accessor: "description",
      sortable: false,
      headerClassName: CommonCss.flexStart,
      Header: uiTexts.description,
      Cell: ({ value }) => <span title={value || ""}>{value || Constants.EMPTY_PLACEHOLDER}</span>
    },
    {
      id: "actions",
      sortable: false,
      width: ACTIONS_CELL_WIDTH,
      className: CommonCss.flexEnd,
      Header: uiTexts.actions,
      Cell: ({ original: { id, status: documentStatus, recogniseData: { previewFile, status } = {} } }) => {
        const extractStatus = documentStatus === DataConstants.STATUSES.TO_EXTRACT;

        const errorState = status === "error";

        return (
          <ActionsButtons
            itemId={id}
            disabledPreview={disabled || !previewFile || !previewFile.key}
            disabledDelete={disabled || (extractStatus && !errorState) || userRestrictions.documentsDelete}
            onPreview={onDocumentPreview}
            onDelete={id === baseDocument.id ? onDocumentDelete : undefined} />
        );
      }
    }
  ],
  [
    uiTexts,
    disabled,
    selectedBusinessData,
    activeOrganization,
    baseDocument,
    userRestrictions,
    onDocumentPreview,
    onDocumentDelete
  ]);

  return (
    <div className={Css.documentsTable}>
      <Table
        columns={tableColumns}
        data={[baseDocument]}
        getTrProps={getTrProps} />
      <div className={Css.message}>
        <span>
          <FiAlertTriangle className={CommonCss.warningText} />
          <span><b>{messages.duplicatedDocumentsMessage}:</b></span>
        </span>
      </div>
      <Table
        key={data.length}
        className={Css.duplicatesTable}
        columns={tableColumns}
        data={data}
        pages={Math.ceil(data.length / TABLE_PAGE_SIZE)}
        showPagination={data.length > TABLE_PAGE_SIZE} />
    </div>
  );
};

export default React.memo(DocumentsTable);
