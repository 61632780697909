import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { ButtonRadioGroup, DebounceInput, Form, ModalWindow, Preloader } from "lib/common";
import { Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Row } from "shards-react";
import { FiFileText, FiLink2 as FiLink } from "react-icons/fi";
import { getCurrenciesData } from "selectors/metaData";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useMainApi } from "hooks";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import LinkDocumentsTable from "lib/common/LinkDocumentsTable";
import MainApiActions from "actions/MainApiActions";
import MainApiRoutes from "const/MainApiRoutes";
import React, { useCallback, useMemo, useState } from "react";
import RestApi from "api/RestApi";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";

const { STATUSES: { TO_REVIEW, TO_REPORT }, DOCUMENT_TYPES: { INVOICE, CREDIT_NOTE, RECEIPT, SALES_RECEIPT } } = DataConstants;

const DOCUMENT_LINK_TABS = {
  LINKED: "linked",
  CREDIT_NOTES: "creditNotes",
  DOCUMENTS: "documents"
};

const { LINKED, CREDIT_NOTES, DOCUMENTS } = DOCUMENT_LINK_TABS;

const MIN_ROWS_COUNT_TO_ENABLE_SEARCH = 7;

const LinkDocumentsWindow = ({ documentType, documentsIds, documentFrozen, onChange, onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const currenciesData = useSelector(getCurrenciesData);

  const [searchText, setSearchText] = useState("");

  const [selectedTab, setSelectedTab] = useState(
    documentsIds.length
      ? LINKED
      : (documentType === CREDIT_NOTE ? DOCUMENTS : CREDIT_NOTES)
  );

  const [modalShown, setModalShown] = useState(false);

  const filters = useMemo(() => {
    const type = documentType === CREDIT_NOTE ? [INVOICE, RECEIPT, SALES_RECEIPT] : CREDIT_NOTE;

    const status = [TO_REVIEW, TO_REPORT];

    const text = searchText ? { text: searchText } : {};

    return { type, status, ...text };
  }, [documentType, searchText]);

  let [{ results: linkedDocumentsData }, fetchingLinkedDocuments] = useMainApi({
    method: RestApi.REQUEST_METHODS.POST,
    initialData: { results: [] },
    fetchCondition: modalShown,
    parameters: [
      `${MainApiRoutes.BUSINESSES}/${selectedBusinessId + MainApiRoutes.DOCUMENTS}`,
      null,
      { documentsIds }
    ],
    dependencies: [modalShown, documentsIds]
  });

  let [{ results: documentsData }, fetchingDocuments] = useMainApi({
    method: RestApi.REQUEST_METHODS.POST,
    initialData: { results: [] },
    fetchCondition: modalShown && (!searchText || searchText.length >= Constants.SEARCH_TEXT_MIN_LENGTH),
    parameters: [
      `${MainApiRoutes.BUSINESSES}/${selectedBusinessId + MainApiRoutes.DOCUMENTS}`,
      null,
      filters
    ],
    dependencies: [modalShown, searchText]
  });

  linkedDocumentsData = useMemo(() => {
    return linkedDocumentsData.map(({ currency, ...restDocument }) => ({
      ...restDocument,
      currency: currenciesData.some(({ code }) => code === currency) ? currency : null
    }));
  }, [linkedDocumentsData, currenciesData]);

  documentsData = useMemo(() => {
    return documentsData.map(({ currency, ...restDocument }) => ({
      ...restDocument,
      currency: currenciesData.some(({ code }) => code === currency) ? currency : null
    }));
  }, [documentsData, currenciesData]);

  const tabsData = useMemo(() => {
    return {
      [LINKED]: {
        fetching: fetchingLinkedDocuments,
        data: linkedDocumentsData
      },
      [CREDIT_NOTES]: {
        fetching: fetchingDocuments,
        data: documentsData
      },
      [DOCUMENTS]: {
        fetching: fetchingDocuments,
        data: documentsData
      }
    }[selectedTab];
  }, [selectedTab, fetchingLinkedDocuments, fetchingDocuments, linkedDocumentsData, documentsData]);

  const tabsList = useMemo(() => {
    return [
      { value: LINKED, label: uiTexts.linked },
      documentType === CREDIT_NOTE
        ? { value: DOCUMENTS, label: uiTexts.documents }
        : { value: CREDIT_NOTES, label: uiTexts.creditNotes }
    ];
  }, [documentType, uiTexts]);

  const searchEnabled = selectedTab !== LINKED && (searchText || documentsData.length >= MIN_ROWS_COUNT_TO_ENABLE_SEARCH);

  const { fetching, data } = tabsData;

  const handleSearchTextInputChange = useCallback((value) => {
    setSearchText(value.length < Constants.SEARCH_TEXT_MIN_LENGTH ? "" : value);
  }, []);

  const handleTableDocumentPreview = useCallback((documentId) => {
    const { recogniseData: { previewFile } = {} } = data.find(({ id }) => id === documentId);

    dispatch(MainApiActions.fetchAttachmentUrl(previewFile)).then((link) => {
      if (link) dispatch(UiActions.showModalImages([link]));
    });
  }, [data, dispatch]);

  const handleTableDocumentLink = useCallback((documentId) => {
    const documentData = documentsData.find((document) => document.id === documentId);

    if (documentData.linkedDocumentsIds && documentData.linkedDocumentsIds.length) {
      dispatch(UiActions.showModal(
        messages.documentAlreadyLinked,
        uiTexts.info,
        false
      ));
    } else onChange([documentId]);
  }, [uiTexts, messages, documentsData, onChange, dispatch]);

  const handleTableDocumentDismiss = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const handleModalShow = useCallback(() => {
    setModalShown(true);
  }, []);

  const handleModalClose = useCallback(() => {
    onChange([...documentsIds]);
    onClose();
  }, [documentsIds, onChange, onClose]);

  return (
    <ModalWindow
      className={Css.linkDocumentsWindow}
      bodyClassName={Css.windowBody}
      disabledOkButton={fetching}
      config={{ headerText: uiTexts.addLinkedDocuments }}
      iconComponent={FiLink}
      onShow={handleModalShow}
      onClose={handleModalClose}>
      <Form>
        <FormGroup row className={CommonCss.flexCenter}>
          <Row>
            <Col>
              <ButtonRadioGroup
                outline
                size="sm"
                theme="light"
                disabled={fetching}
                initialValue={selectedTab}
                options={tabsList}
                onChange={setSelectedTab} />
            </Col>
          </Row>
        </FormGroup>
        {searchEnabled && (
          <FormGroup row>
            <Row form>
              <Col>
                <InputGroup className={Css.searchInputGroup}>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>{uiTexts.searchDocuments}</InputGroupText>
                  </InputGroupAddon>
                  <DebounceInput
                    autoComplete="off"
                    type="search"
                    value={searchText}
                    placeholder={Utils.replaceTextVars(
                      messages.searchDocumentsPlaceholder,
                      { minSymbols: Constants.SEARCH_TEXT_MIN_LENGTH }
                    )}
                    disabled={fetching}
                    onInputComplete={handleSearchTextInputChange} />
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
        )}
        <FormGroup row>
          <Row form>
            <Col className={Css.tableColumn}>
              {fetching
                ? <Preloader />
                : (data.length
                  ? <div className={Css.tableWrapper}>
                    <LinkDocumentsTable
                      className={Css.documentsTable}
                      disabled={fetchingLinkedDocuments || documentFrozen}
                      data={data}
                      linkedDocuments={documentsIds}
                      onDocumentPreview={handleTableDocumentPreview}
                      onDocumentLink={handleTableDocumentLink}
                      onDocumentLinkDismiss={handleTableDocumentDismiss} />
                  </div>
                  : <div className={CommonCss.noDataContent}>
                    <div>
                      <div><FiFileText /></div>
                      <div>{uiTexts.noDocumentsFound}</div>
                    </div>
                  </div>)}
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </ModalWindow>
  );
};

export default LinkDocumentsWindow;
