import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Badge, ButtonRadioGroup } from "lib/common";
import { FiAlertCircle, FiEdit, FiMessageCircle, FiRefreshCw, FiUser } from "react-icons/fi";
import { connect } from "react-redux";
import { getActiveOrganization } from "selectors/organizations";
import { getContactsData } from "selectors/contacts";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import DataConstants from "const/DataConstants";
import React, { PureComponent } from "react";

const mapStateToProps = (state, { vendorId }) => ({
  activeOrganization: getActiveOrganization(state),
  selectedBusinessData: getSelectedBusinessData(state),
  contactData: (vendorId && getContactsData(state).find(({ id }) => id === vendorId)) || {},
  textsData: getTextsData(state)
});

@connect(mapStateToProps)
class EditDocumentWindowModeSelector extends PureComponent {
  static MODES = {
    DEFAULT: "default",
    CONTACT: "contact",
    HISTORY: "history",
    COMMENTS: "comments"
  };

  render() {
    const { MODES } = EditDocumentWindowModeSelector;

    const {
      textsData: { uiTexts },
      contactData,
      status,
      comments,
      selectedEditTab,
      disabled,
      onChange
    } = this.props;

    const excludedStatus = status === DataConstants.STATUSES.EXCLUDED;

    const { unreliableVatPayerSince, vatId, vatPayer } = contactData || {};

    const showContactWarning = !excludedStatus && contactData
      && (unreliableVatPayerSince || (vatId && vatPayer === false));

    return (
      <ButtonRadioGroup
        outline
        theme="light"
        key={selectedEditTab}
        className={Css.editDocumentWindowModeSelector}
        disabled={disabled}
        initialValue={selectedEditTab}
        options={[
          {
            value: MODES.DEFAULT,
            label: <><FiEdit /><span>{uiTexts.document}</span></>
          },
          {
            value: MODES.CONTACT,
            label: <>
              {showContactWarning
                ? <FiAlertCircle className={unreliableVatPayerSince ? CommonCss.warningText : CommonCss.negativeText} />
                : <FiUser />}
              <span>{uiTexts.contact}</span>
            </>
          },
          {
            value: MODES.HISTORY,
            label: <>
              <FiRefreshCw />
              <span>{uiTexts.changes}</span>
            </>
          },
          {
            value: MODES.COMMENTS,
            label: <>
              <FiMessageCircle />
              <span>{uiTexts.comments}</span>
              {!!comments.all && selectedEditTab !== MODES.COMMENTS && <Badge
                counter
                data-unread={comments.unread ? "" : undefined}
                value={comments.all} />}
            </>
          }
        ]}
        onChange={onChange} />
    );
  }
}

export const { MODES: EDIT_DOCUMENT_WINDOW_MODES } = EditDocumentWindowModeSelector;

export default EditDocumentWindowModeSelector;
