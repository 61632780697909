import Css from "../../style.module.scss";

import {
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import { FiSettings } from "react-icons/fi";
import { checkVatClassificationFetching, getVatClassificationData } from "selectors/vatClassification";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import EditVatClassificationModal from "./lib/EditVatClassificationModal";
import React, { useCallback, useMemo, useState } from "react";
import SelectVatClassification from "./lib/SelectVatClassification";
import VatClassificationActions from "actions/VatClassificationActions";

const VatClassificationComponent = ({ value, disabled, issueDate, onChange }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const [modalShown, setModalShown] = useState(false);

  const uniqueId = useMemo(() => uuid(), []);

  const fetchingData = useSelector(checkVatClassificationFetching);

  const vatClassification = useSelector(getVatClassificationData);

  const handleEditButtonClose = useCallback(() => {
    setModalShown(true);
  }, []);

  const handleModalClose = useCallback(async(result) => {
    setModalShown(false);
    if (result) {
      const itemsToAdd = vatClassification
        .filter((item) => item.default && result.includes(item.id))
        .map(({ name, code }) => ({ name, code }));

      const itemsToDelete = vatClassification
        .filter((item) => !item.default && !result.includes(item.id))
        .map((item) => ({ ...item, deleted: true }));

      if (itemsToAdd.length) {
        await dispatch(VatClassificationActions.create(itemsToAdd));
      }

      if (itemsToDelete.length) {
        await dispatch(VatClassificationActions.delete(itemsToDelete));
      }
    }
  }, [dispatch, vatClassification]);

  return (
    <>
      <InputGroup className={Css.pohodaExtraFields}>
        <InputGroupAddon type="prepend">
          <InputGroupText title={uiTexts.vatClassification}>
            {uiTexts.vatClassification}
          </InputGroupText>
        </InputGroupAddon>
        <SelectVatClassification
          dropup
          issueDate={issueDate}
          name={uniqueId.current}
          value={value}
          disabled={disabled || fetchingData}
          onChange={onChange} />
        <InputGroupAddon type="append">
          <Button
            outline
            theme="secondary"
            disabled={disabled || fetchingData}
            className={Css.extraSettingsButton}
            onClick={handleEditButtonClose}><FiSettings /></Button>
        </InputGroupAddon>
      </InputGroup>
      {modalShown && (
        <EditVatClassificationModal onClose={handleModalClose} />
      )}
    </>
  );
};

export default React.memo(VatClassificationComponent);
