import Constants from "const/Constants";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import Utils from "./Utils";

export default class Documents {
  static getDetailedTaxRates(paymentType, lineItems = []) {
    return Object.values(lineItems
      .map(({
        amountBase,
        taxRate: { rateDetails: { purchase = [], sales = [] } = {} } = {}
      }) => {
        const details = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY ? purchase : sales;

        amountBase = amountBase || 0;

        return details.map(({ id, rate, name }) => ({
          id,
          rate,
          name,
          value: Utils.toMoneyNumber(amountBase * rate / Constants.PERCENTS_MULTIPLIER),
          taxableAmount: amountBase
        }));
      })
      .flat()
      .reduce((aggregator, taxRateDetails) => {
        const currentValue = (aggregator[taxRateDetails.id] || {}).value || 0;

        const currentTaxableAmount = (aggregator[taxRateDetails.id] || {}).taxableAmount || 0;

        return {
          ...aggregator,
          [taxRateDetails.id]: {
            ...taxRateDetails,
            value: Utils.toMoneyNumber(currentValue + taxRateDetails.value),
            taxableAmount: Utils.toMoneyNumber(currentTaxableAmount + taxRateDetails.taxableAmount)
          }
        };
      }, {}))
      .sort((valueA, valueB) => valueA.rate - valueB.rate);
  }

  static calculateLineItemsData({ lineItems, paymentType, countryCode, quickBooksBusiness }) {
    const usCountry = countryCode === Countries.US;

    const detailedTaxRates = quickBooksBusiness && !usCountry && Documents.getDetailedTaxRates(paymentType, lineItems);

    const amountBase = Utils.toMoneyNumber(
      lineItems.reduce((aggregator, item) => aggregator + (item.amountBase || 0), 0)
    );

    const amountVat = detailedTaxRates
      ? Utils.toMoneyNumber(
        detailedTaxRates.reduce((aggregator, { value }) => aggregator + (value || 0), 0)
      )
      : Utils.toMoneyNumber(
        lineItems.reduce((aggregator, item) => aggregator + (item.amountVat || 0), 0)
      );

    return {
      amountBase,
      amountVat,
      amount: Utils.toMoneyNumber(amountBase + amountVat),
      detailedTaxRates: detailedTaxRates || undefined,
      taxesHash: lineItems.map((item) => item.taxRate?.id).filter(Boolean).join()
    };
  }

  static checkAmountDataConsistence({ documentData, countryCode, vatPayer, quickBooksBusiness }) {
    let { amountBase, amountVat, amountVatRates = [], paymentType, lineItems } = documentData;

    amountBase = Utils.toMoneyNumber(amountBase || 0);

    amountVat = Utils.toMoneyNumber(amountVat || 0);

    if (countryCode === Countries.CZ) {
      if (documentData.status !== DataConstants.STATUSES.TO_REVIEW) return true;

      const invalidAmountBase = amountBase === "" || isNaN(+amountBase) || +amountBase < 0;

      if (invalidAmountBase) return false;

      const amount = Utils.toMoneyNumber(amountBase + amountVat);

      if (!vatPayer) return amountBase === amount;

      const totalBaseValue = amountVatRates.reduce((aggregator, currentValue) => aggregator + currentValue.base || 0, 0);

      const totalVatValue = amountVatRates.reduce((aggregator, currentValue) => aggregator + currentValue.value || 0, 0);

      const totalValue = Utils.toMoneyNumber(totalBaseValue + totalVatValue);

      return totalValue === amount;
    }

    const lineItemsData = Documents.calculateLineItemsData({ lineItems, paymentType, countryCode, quickBooksBusiness });

    return amountBase === lineItemsData.amountBase
      && (!!lineItemsData.detailedTaxRates?.length || amountVat === lineItemsData.amountVat);
  }
}
