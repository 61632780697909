import { FormCheckbox } from "shards-react";
import DataConstants from "const/DataConstants";
import React, { useMemo } from "react";

const { STATUSES } = DataConstants;

const IdColumnHeader = ({ data, checkBoxes, disabled, onHeaderCheckBoxChange }) => {
  const filteredData = useMemo(() => {
    return data.filter(({ status }) => status !== STATUSES.TO_EXTRACT);
  }, [data]);

  const checked = useMemo(() => {
    return filteredData.length && !filteredData.some(({ id }) => !checkBoxes[id]);
  }, [checkBoxes, filteredData]);

  return (
    <FormCheckbox
      checked={checked}
      disabled={disabled || !filteredData.length}
      onChange={onHeaderCheckBoxChange} />
  );
};

export default React.memo(IdColumnHeader);
