import { AutoTooltip } from "lib/common";
import Constants from "const/Constants";
import React from "react";
import moment from "moment";

const IssueDateCell = ({ value }) => {
  return (
    <AutoTooltip active={!!value}>
      {value
        ? moment.utc(value).format(Constants.DATETIME_FORMATS.DATE_TEXT)
        : Constants.EMPTY_PLACEHOLDER}
    </AutoTooltip>
  );
};

export default React.memo(IssueDateCell);
