import { FiCheckCircle, FiCloud, FiCloudOff, FiExternalLink, FiEye, FiList, FiMinusCircle } from "react-icons/fi";
import DataConstants from "const/DataConstants";

export default class DocumentsStatuses {
  static getStatusData(status) {
    const { TO_EXTRACT, TO_REPORT, TO_REVIEW, EXCLUDED, EXPORTED } = DataConstants.STATUSES;

    const statusesData = {
      [TO_EXTRACT]: {
        value: TO_EXTRACT,
        iconComponent: FiCloud,
        errorIconComponent: FiCloudOff,
        labelLangId: "processing",
        descriptionMessageId: "toExtractDocumentsDescription"
      },
      [TO_REVIEW]: {
        value: TO_REVIEW,
        canBeAssigned: true,
        iconComponent: FiEye,
        labelLangId: "toReview",
        descriptionMessageId: "toReviewDocumentsDescription"
      },
      [EXCLUDED]: {
        value: EXCLUDED,
        canBeAssigned: true,
        iconComponent: FiMinusCircle,
        labelLangId: "excluded",
        descriptionMessageId: "excludedDocumentsDescription"
      },
      [TO_REPORT]: {
        value: TO_REPORT,
        canBeAssigned: true,
        iconComponent: FiCheckCircle,
        labelLangId: "approved",
        descriptionMessageId: "toReportDocumentsDescription"
      },
      [EXPORTED]: {
        value: EXPORTED,
        iconComponent: FiExternalLink,
        labelLangId: "exported",
        descriptionMessageId: "exportedDocumentsDescription"
      },
      [null]: {
        value: null,
        iconComponent: FiList,
        labelLangId: "all",
        descriptionMessageId: "allDocumentsDescription"
      }
    };

    return status === undefined ? statusesData : statusesData[status];
  }
}
