import Css from "./style.module.scss";

import { Button, Dropdown, DropdownMenu, FormGroup, FormInput, InputGroup, InputGroupAddon, InputGroupText } from "shards-react";
import { FiPlus } from "react-icons/fi";
import { Form, ModalWindow } from "lib/common";
import { checkPreAccountingFetching, getPreAccountingData } from "selectors/preAccounting";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import IncludeExclude from "lib/common/IncludeExclude";
import PreAccountingActions from "actions/PreAccountingActions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Utils from "utils/Utils";

const INITIAL_ITEM_DATA = { name: "", code: "" };

const EditPreAccountingModal = ({ onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const fetchingData = useSelector(checkPreAccountingFetching);

  const preAccounting = useSelector(getPreAccountingData);

  const usedCodes = useMemo(() => {
    return preAccounting
      .filter((item) => !item.default).map(({ code }) => code);
  }, [preAccounting]);

  const options = useMemo(() => {
    return Utils.arraySort(
      preAccounting
        .filter((item) => !item.default || !usedCodes.includes(item.code))
        .map((item) => ({ value: item.id, label: `${item.code}: ${item.name}` })),
      (item) => item.label.toLocaleLowerCase(),
      true
    );
  }, [usedCodes, preAccounting]);

  const [value, setValue] = useState([]);

  const [newItemData, setNewItemData] = useState(INITIAL_ITEM_DATA);

  const [dropdownOpened, setDropdownOpened] = useState(false);

  const handleClose = useCallback((result) => {
    onClose(result && value);
  }, [onClose, value]);

  const handleAddNewButtonClick = useCallback(() => {
    setDropdownOpened(true);
  }, []);

  const handleDropdownAddButtonClick = useCallback(() => {
    dispatch(PreAccountingActions.create([newItemData]));
    setNewItemData(INITIAL_ITEM_DATA);
    setDropdownOpened(false);
  }, [dispatch, newItemData]);

  const handleDropdownCloseButtonClick = useCallback(() => {
    setDropdownOpened(false);
  }, []);

  const handleNameInputChange = useCallback(({ target: { value: name } }) => {
    setNewItemData((prev) => ({ ...prev, name }));
  }, []);

  const handleCodeInputChange = useCallback(({ target: { value: code } }) => {
    setNewItemData((prev) => ({ ...prev, code }));
  }, []);

  useEffect(() => {
    setValue(preAccounting
      .filter((item) => !item.default).map(({ id }) => id));
  }, [preAccounting]);

  return (
    <ModalWindow
      config={{
        headerText: uiTexts.configurePreAccounting,
        okButtonText: uiTexts.save,
        confirm: true
      }}
      className={Css.editPreAccountingModal}
      onClose={handleClose}>
      <IncludeExclude
        disabled={fetchingData}
        className={Css.includeExclude}
        value={value}
        options={options}
        onChange={setValue} />
      <div className={Css.footer}>
        <Button
          outline
          theme="primary"
          size="sm"
          disabled={fetchingData}
          onClick={handleAddNewButtonClick}>
          <FiPlus />
          {uiTexts.addNew}
        </Button>
        <Dropdown dropup open={dropdownOpened} toggle={setDropdownOpened}>
          <DropdownMenu className={Css.dropdown}>
            <Form>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon type="prepend">
                    <InputGroupText><span>{uiTexts.code}</span></InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    placeholder={uiTexts.enterCode}
                    value={newItemData.code}
                    invalid={false}
                    onChange={handleCodeInputChange} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon type="prepend">
                    <InputGroupText><span>{uiTexts.description}</span></InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    placeholder={uiTexts.enterDescription}
                    value={newItemData.name}
                    invalid={false}
                    onChange={handleNameInputChange} />
                </InputGroup>
              </FormGroup>
            </Form>
            <div className={Css.buttonWrapper}>
              <Button
                outline
                theme="primary"
                size="sm"
                disabled={!newItemData.name.trim() || !newItemData.code.trim()}
                onClick={handleDropdownAddButtonClick}>
                {uiTexts.save}
              </Button>
              <Button
                outline
                theme="danger"
                size="sm"
                disabled={null}
                onClick={handleDropdownCloseButtonClick}>
                {uiTexts.close}
              </Button>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    </ModalWindow>
  );
};

export default React.memo(EditPreAccountingModal);
