import { AutoCompleteInput } from "lib/common";
import { checkPreAccountingFetching, getPreAccountingData } from "selectors/preAccounting";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useCallback, useMemo, useRef, useState } from "react";
import Utils from "utils/Utils";

const { DATA_SIMILARITY_COEFFICIENT } = Constants;

const SelectPreAccounting = (props) => {
  const {
    value,
    invalid,
    disabled,
    inputProps,
    useTooltip,
    onChange,
    ...restProps
  } = props;

  const inputRef = useRef();

  const { uiTexts } = useSelector(getTextsData);

  const fetchingData = useSelector(checkPreAccountingFetching);

  const preAccounting = useSelector(getPreAccountingData);

  const filteredData = useMemo(() => {
    return preAccounting.filter((item) => !item.default);
  }, [preAccounting]);

  const [inputValue, setInputValue] = useState(() => {
    const found = Utils.arrayFind(filteredData, "code", value);

    return found ? `${found.code}: ${found.name}` : (value || "");
  });

  const options = useMemo(() => {
    return filteredData
      .map((item) => `${item.code}: ${item.name}`)
      .sort((itemA, itemB) => itemA.localeCompare(itemB));
  }, [filteredData]);

  const handleChange = useCallback((event) => {
    setInputValue(event.target.value);
    onChange(null);
  }, [onChange]);

  const handleAutoComplete = useCallback((event) => {
    const [newCode] = event.target.value.split(":");

    const found = Utils.arrayFind(filteredData, "code", newCode);

    if (found) {
      onChange(found.code);
      setInputValue(`${found.code}: ${found.name}`);
    }
  }, [filteredData, onChange]);

  return (
    <AutoCompleteInput
      {...restProps}
      title={inputValue}
      disabled={disabled || fetchingData}
      invalid={invalid || (!!(value || inputValue) && !Utils.arrayFind(filteredData, "code", value))}
      ref={inputRef}
      placeholder={uiTexts.selectPreAccounting}
      value={inputValue}
      similarityCoef={DATA_SIMILARITY_COEFFICIENT}
      toggleProps={inputProps}
      options={options}
      onChange={handleChange}
      onAutoComplete={handleAutoComplete} />
  );
};

export default React.memo(SelectPreAccounting);
