
import Css from "./style.module.scss";

import { Button, Dropdown, DropdownMenu, FormInput } from "shards-react";
import { bind } from "decko";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import Constants from "const/Constants";
import React, { PureComponent } from "react";
import Utils from "utils/Utils";
import moment from "moment";

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

@connect(mapStateToProps, null)
class MultiAmountWidget extends PureComponent {
  static expandedInstance = null;

  state = { expanded: false, changed: false };

  emptyFunction = () => null;

  get vatRates() {
    const { issueDate } = this.props;

    return issueDate && moment(issueDate).isBefore(Constants.CZ_NEW_VAT_DATE)
      ? Constants.OLD_VAT_RATES : Constants.VAT_RATES;
  }

  get totalValues() {
    const { value } = this.props;

    const totalBase = value.reduce((aggregator, currentValue) => aggregator + currentValue.base || 0, 0);

    const totalVat = value.reduce((aggregator, currentValue) => aggregator + currentValue.value || 0, 0);

    const total = totalBase + totalVat;

    return { totalBase, totalVat, total };
  }

  checkValidity(rowIndex) {
    return this.vatRates.every((vatRate, index) => {
      if (rowIndex !== undefined && rowIndex !== index) return true;

      const vatRateData = this.props.value.find(({ rate }) => rate === vatRate) || {};

      if (!vatRate) return !vatRateData.base || (vatRateData.base >= 0 && !vatRateData.value);

      return (!vatRateData.base && !vatRateData.value) || ((vatRateData.base || 0) > 0 && (vatRateData.value || 0) > 0);
    });
  }

  normalizeValues(values, strict = true) {
    const newValues = values.map((value) => {
      if (strict) {
        if (value.base === undefined && value.value === undefined) return undefined;
      } else if (!value.base && !value.value) return undefined;

      return {
        rate: value.rate,
        base: (value.base || value.base === 0) ? value.base : undefined,
        value: (value.value || value.value === 0) ? value.value : undefined
      };
    }).filter(Boolean);

    if (!newValues.length) newValues.push({ rate: 0, base: 0, value: 0 });

    return newValues;
  }

  @bind
  handleToggleItemClick() {
    this.setState(
      (prevState) => ({ expanded: !prevState.expanded }),
      () => {
        if (this.state.expanded) {
          if (MultiAmountWidget.expandedInstance) MultiAmountWidget.expandedInstance.setState({ expanded: false });
          MultiAmountWidget.expandedInstance = this;
        } else {
          const { value: amountVatRates, correction, onChange } = this.props;

          if (amountVatRates.length && !this.props.disabledFields && this.checkValidity()) {
            const newValue = this.vatRates.map((vatRate) => {
              const vatRateData = amountVatRates.find(({ rate }) => rate === vatRate) || {};

              const base = Utils.toMoneyNumber(vatRateData.base) || (vatRateData.base === 0 ? 0 : undefined);

              const value = Utils.toMoneyNumber(vatRateData.value) || (vatRateData.value === 0 ? 0 : undefined);

              return { rate: vatRate, base, value };
            });

            onChange({ value: this.normalizeValues(newValue, false), correction });
          }
          MultiAmountWidget.expandedInstance = null;
        }
      }
    );
  }

  @bind
  handleBaseInputChange({ target: { dataset: { index: inputIndex }, value: inputValue } }) {
    const { PERCENTS_MULTIPLIER } = Constants;

    const { value: amountVatRates, correction, calculateInput, onChange } = this.props;

    const newValues = this.vatRates.map((vatRate, index) => {
      const vatRateData = amountVatRates.find(({ rate }) => rate === vatRate) || {};

      const inputEdited = +inputIndex === index;

      const base = inputEdited ? inputValue : vatRateData.base;

      const value = inputEdited && calculateInput ? base * (vatRate / PERCENTS_MULTIPLIER) : vatRateData.value;

      return {
        rate: vatRate,
        base: (base || base === 0) ? Utils.toMoneyNumber(base) : undefined,
        value: (value || value === 0) ? Utils.toMoneyNumber(value) : undefined
      };
    });

    this.setState({ changed: true });
    onChange({ value: this.normalizeValues(newValues), correction });
  }

  @bind
  handleVatInputChange({ target: { dataset: { index: inputIndex }, value: inputValue } }) {
    const { value: amountVatRates, correction, onChange } = this.props;

    const newValues = this.vatRates.map((vatRate, index) => {
      const vatRateData = amountVatRates.find(({ rate }) => rate === vatRate) || {};

      const inputEdited = +inputIndex === index;

      const value = inputEdited ? inputValue : vatRateData.value;

      const { base } = vatRateData;

      return {
        rate: vatRate,
        base: Utils.toMoneyNumber(base) || undefined,
        value: (value || value === 0) ? Utils.toMoneyNumber(value) : undefined
      };
    });

    this.setState({ changed: true });
    onChange({ value: this.normalizeValues(newValues), correction });
  }

  @bind
  handleTotalInputChange({ target: { dataset: { index: inputIndex }, value: inputValue } }) {
    const { PERCENTS_MULTIPLIER } = Constants;

    const { value: amountVatRates, correction, onChange } = this.props;

    const newValues = this.vatRates.map((vatRate, index) => {
      const vatRateData = amountVatRates.find(({ rate }) => rate === vatRate) || {};

      const inputEdited = +inputIndex === index;

      const base = inputEdited ? (inputValue && !isNaN(+inputValue)
        ? inputValue / (vatRate + PERCENTS_MULTIPLIER) * PERCENTS_MULTIPLIER
        : undefined) : vatRateData.base;

      const value = inputEdited ? (inputValue && !isNaN(+inputValue)
        ? inputValue / (vatRate + PERCENTS_MULTIPLIER) * vatRate
        : undefined) : vatRateData.value;

      return {
        rate: vatRate,
        base: (base || base === 0) ? Utils.toMoneyNumber(base) : undefined,
        value: (value || value === 0) ? Utils.toMoneyNumber(value) : undefined
      };
    });

    this.setState({ changed: true });
    onChange({ value: this.normalizeValues(newValues), correction });
  }

  @bind
  handleCorrectionInputChange({ target: { value } }) {
    const { value: amountVatRates, onChange } = this.props;

    if (value === "") onChange({ value: amountVatRates });
    else {
      const { total } = this.totalValues;

      const correction = Utils.toMoneyNumber(value);

      onChange({ value: amountVatRates, correction: total + correction > 0 ? correction : -total });
    }
  }

  render() {
    const {
      textsData: { uiTexts },
      base,
      vat,
      value,
      correction,
      vatPayer,
      disabled,
      disabledFields,
      invalid,
      valid,
      inputClassName
    } = this.props;

    const { expanded, changed } = this.state;

    let { totalBase, totalVat, total } = this.totalValues;

    if (base) total += correction || 0;

    return (
      <Dropdown open={expanded} className={Css.multiAmountWidget} toggle={this.emptyFunction}>
        <FormInput
          readOnly
          className={inputClassName}
          value={value.length && vatPayer
            ? Utils.toMoneyNumber((base ? total : (vat ? totalVat : total)) || 0)
            : (vatPayer ? "" : uiTexts.notVatPayer)}
          invalid={invalid}
          valid={valid}
          placeholder={base ? uiTexts.enterSubtotal : vat && (vatPayer ? uiTexts.enterVat : "")}
          disabled={disabled}
          onClick={this.handleToggleItemClick} />
        {expanded && (
          <DropdownMenu className={Css.menu}>
            <div><span /><div>{uiTexts.subtotal}</div><div>{uiTexts.vat}</div><div>{uiTexts.total}</div></div>
            {this.vatRates.map((vatRate, index) => {
              const vatRateData = value.find(({ rate }) => rate === vatRate) || {};

              const subTotal = vatRateData.base === undefined || vatRateData.value === undefined
                ? undefined
                : vatRateData.base + vatRateData.value;

              const baseInputValue = vatRateData.base || vatRateData.base === 0
                ? Utils.toMoneyNumber(vatRateData.base)
                : "";

              const vatInputValue = vatRateData.value || vatRateData.value === 0
                ? Utils.toMoneyNumber(vatRateData.value)
                : (!vatRate && vatRateData.base ? 0 : "");

              const totalInputValue = (subTotal || subTotal === 0) ? Utils.toMoneyNumber(subTotal) : "";

              return (
                <div key={vatRate}>
                  <span>{vatRate}%</span>
                  <FormInput
                    type="number"
                    step="any"
                    min={0}
                    data-index={index}
                    placeholder={Constants.EMPTY_PLACEHOLDER}
                    value={baseInputValue}
                    invalid={!this.checkValidity(index)}
                    disabled={disabledFields}
                    onChange={this.handleBaseInputChange} />
                  <FormInput
                    type="number"
                    step="any"
                    min={0}
                    data-index={index}
                    placeholder={Constants.EMPTY_PLACEHOLDER}
                    value={vatInputValue}
                    invalid={!this.checkValidity(index)}
                    disabled={!vatRate || disabledFields}
                    onChange={this.handleVatInputChange} />
                  <FormInput
                    type="number"
                    step="any"
                    min={0}
                    data-index={index}
                    placeholder={Constants.EMPTY_PLACEHOLDER}
                    value={totalInputValue}
                    invalid={!this.checkValidity(index)}
                    disabled={disabledFields}
                    onChange={this.handleTotalInputChange} />
                </div>
              );
            })}
            {vat ? null : <div>
              <span>+/-</span>
              <FormInput
                type="number"
                step="any"
                className={Css.correctionInput}
                placeholder={Constants.EMPTY_PLACEHOLDER}
                value={correction === undefined ? "" : correction}
                disabled={disabledFields}
                onChange={this.handleCorrectionInputChange} />
            </div>}
            <hr />
            <div>
              <span />
              <FormInput
                disabled
                value={Utils.toMoneyNumber(totalBase) || Constants.EMPTY_PLACEHOLDER} />
              <FormInput
                disabled
                value={Utils.toMoneyNumber(totalVat) || Constants.EMPTY_PLACEHOLDER} />
              <FormInput
                disabled
                value={Utils.toMoneyNumber(total) || Constants.EMPTY_PLACEHOLDER} />
            </div>
            <div>
              <Button
                size="sm"
                theme={changed || !invalid ? "primary" : "success"}
                disabled={!this.checkValidity()}
                onClick={this.handleToggleItemClick}>
                {changed || !invalid ? uiTexts.ok : uiTexts.confirmAmount}
              </Button>
            </div>
          </DropdownMenu>
        )}
      </Dropdown>
    );
  }
}

export default MultiAmountWidget;
