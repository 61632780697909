import Css from "../../style.module.scss";

import {
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import { FiSettings } from "react-icons/fi";
import { checkPreAccountingFetching, getPreAccountingData } from "selectors/preAccounting";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import EditPreAccountingModal from "./lib/EditPreAccountingModal";
import PreAccountingActions from "actions/PreAccountingActions";
import React, { useCallback, useMemo, useState } from "react";
import SelectPreAccounting from "./lib/SelectPreAccounting";

const PreAccountingComponent = ({ value, disabled, onChange }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const [modalShown, setModalShown] = useState(false);

  const uniqueId = useMemo(() => uuid(), []);

  const fetchingData = useSelector(checkPreAccountingFetching);

  const preAccounting = useSelector(getPreAccountingData);

  const handleEditButtonClose = useCallback(() => {
    setModalShown(true);
  }, []);

  const handleModalClose = useCallback(async(result) => {
    setModalShown(false);

    if (result) {
      const itemsToAdd = preAccounting
        .filter((item) => item.default && result.includes(item.id))
        .map(({ name, code }) => ({ name, code }));

      const itemsToDelete = preAccounting
        .filter((item) => !item.default && !result.includes(item.id))
        .map((item) => ({ ...item, deleted: true }));

      if (itemsToAdd.length) {
        await dispatch(PreAccountingActions.create(itemsToAdd));
      }

      if (itemsToDelete.length) {
        await dispatch(PreAccountingActions.delete(itemsToDelete));
      }
    }
  }, [dispatch, preAccounting]);

  return (
    <>
      <InputGroup className={Css.pohodaExtraFields}>
        <InputGroupAddon type="prepend">
          <InputGroupText title={uiTexts.preAccounting}>
            {uiTexts.preAccounting}
          </InputGroupText>
        </InputGroupAddon>
        <SelectPreAccounting
          dropup
          name={uniqueId.current}
          value={value}
          disabled={disabled || fetchingData}
          onChange={onChange} />
        <InputGroupAddon type="append">
          <Button
            outline
            theme="secondary"
            disabled={disabled || fetchingData}
            className={Css.extraSettingsButton}
            onClick={handleEditButtonClose}><FiSettings /></Button>
        </InputGroupAddon>
      </InputGroup>
      {modalShown && (
        <EditPreAccountingModal onClose={handleModalClose} />
      )}
    </>
  );
};

export default React.memo(PreAccountingComponent);
