import Css from "./style.module.scss";

import { Button } from "shards-react";
import { CSSTransition as CssTransition } from "react-transition-group";
import { FiCheckCircle } from "react-icons/fi";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback } from "react";
import Utils from "utils/Utils";

const TRANSITION_DURATION = 300;

const DocumentsTablePopup = ({ value, onApprove }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const handleApproveButtonClick = useCallback(() => {
    onApprove();
  }, [onApprove]);

  return (
    <CssTransition unmountOnExit in={!!value} classNames="transition" timeout={TRANSITION_DURATION}>
      <div className={Css.documentsTablePopup}>
        <span>{Utils.replaceTextVars(messages.documentsReadyToApprove, { documentsCount: value })}</span>
        <Button
          theme="success"
          className={Css.actionButton}
          onClick={handleApproveButtonClick}>
          <FiCheckCircle />
          <span>{uiTexts.approveAll}</span>
        </Button>
      </div>
    </CssTransition>
  );
};

export default React.memo(DocumentsTablePopup);
